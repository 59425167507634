import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      placeholder: "Type song, artist or track",
      guess: "Guess",
      share: "Share",
      showOnline: "Check out some flag info ℹ️",
      welldone: "Well done!",
      unknownCountry: "Unknown country!",
      copy: "Results copied to clipboard",
      showCountry: "🏳 Show flag!",
      cancelRotation: "🌀 Cancel rotation",
      settings: {
        title: "Settings",
        distanceUnit: "Unit of distance",
        theme: "Theme",
        difficultyModifiers: "Difficulty modifiers",
        startingNextDay: "Starting the next day!",
        noImageMode: "Hide country image for more of a challenge.",
        rotationMode: "Randomly rotate country image.",
      },
      stats: {
        title: "Statistics",
        played: "Played",
        win: "Win %",
        currentStreak: "Current Streak",
        maxStreak: "Max Streak",
        averageBestDistance: "Best Distances Average",
        guessDistribution: "Guess distribution:",
      },
      install: {
        title: "Warbl",
        descritpionTitle: "App Install:",
        description: "Add Warbl to Home Screen to play it easily!",
        instructionTitle: "Instructions:",
        instructionActionOk: "OK",
        instructionActionCancel: "Cancel",
        instructionActionInstall: "Install",
        instructionFirefoxAction1: "- open browser options ",
        instructionFirefoxAction2: "- add to Home Screen",
        instructionFirefoxNewAction1: "- open browser options ",
        instructionFirefoxNewAction2: '- select "Install"',
        instructionIdeviceAction1: "- on Safari, open the Share menu ",
        instructionIdeviceAction2: '- select "Add to Home Screen"',
        instructionOperaAction1: "- press the menu button ",
        instructionOperaAction2: "- add to Home Screen",
        instructionNotSupported: "Not supported by this browser.",
      },
      support: {
        UA: "Support the Ukrainian Red Cross",
      },
      buyMeACoffee: "Buy me a ☕!",
    },
  },
  // fr: {
  //   translation: {
  //     placeholder: "Pays, territoires...",
  //     guess: "Deviner",
  //     share: "Partager",
  //     showOnline: "👀 sur Google Maps",
  //     welldone: "Bien joué !",
  //     unknownCountry: "Pays inconnu !",
  //     copy: "Résultat copié !",
  //     showCountry: "🗺️ Afficher la carte !",
  //     cancelRotation: "🌀 Annule la rotation",
  //     settings: {
  //       title: "Paramètres",
  //       distanceUnit: "Unité de distance",
  //       theme: "Thème",
  //       difficultyModifiers: "Modificateurs de difficulté",
  //       startingNextDay: "A partir du lendemain !",
  //       noImageMode: "Cache l'image du pays pour plus de challenge.",
  //       rotationMode: "Tourne l'image du pays de manière aléatoire.",
  //     },
  //     stats: {
  //       title: "Statistiques",
  //       played: "Parties",
  //       win: "Victoires %",
  //       currentStreak: "Série Actuelle",
  //       maxStreak: "Série Max",
  //       averageBestDistance: "Moyenne Meilleures Distances",
  //       guessDistribution: "Répartitions des victoires:",
  //     },
  //     install: {
  //       title: "Warbl",
  //       descritpionTitle: "Installer l'app:",
  //       description:
  //         "Ajouter Warbl sur l'écran d'accueil pour le retrouver plus facilement !",
  //       instructionTitle: "Instructions :",
  //       instructionActionOk: "OK",
  //       instructionActionCancel: "Annuler",
  //       instructionActionInstall: "Installer",
  //       instructionFirefoxAction1: "- ouvrir les options du navigateur ",
  //       instructionFirefoxAction2: "- ajouter à votre écran d'accueil",
  //       instructionFirefoxNewAction1: "- ouvrir les options du navigateur ",
  //       instructionFirefoxNewAction2: '- sélectionner "Installer"',
  //       instructionIdeviceAction1: "- sur Safari, ouvrir le menu de partage ",
  //       instructionIdeviceAction2: "- sélectionner \"Sur l'écran d'accueil\"",
  //       instructionOperaAction1: "- appuyer sur le bouton menu ",
  //       instructionOperaAction2: "- ajouter à l'écran d'accueil",
  //       instructionNotSupported: "Impossible sur ce navigateur.",
  //     },
  //     support: {
  //       UA: "Soutenez la Croix Rouge Ukrainienne",
  //     },
  //     buyMeACoffee: "Offrez moi un ☕ !",
  //   },
  // },
  // es: {
  //   translation: {
  //     placeholder: "País, Territorios...",
  //     guess: "Adivinar",
  //     share: "Compartir",
  //     showOnline: "👀 en Google Maps",
  //     welldone: "Bien hecho !",
  //     unknownCountry: "País desconocido !",
  //     copy: "Resultado copiado !",
  //     showCountry: "🗺️ mostrar mapa !",
  //     cancelRotation: "🌀 Anular la rotacíon",
  //     settings: {
  //       title: "Parámetros",
  //       distanceUnit: "Unidad de distancia",
  //       theme: "Tema",
  //       difficultyModifiers: "Modificador de dificultad",
  //       startingNextDay: "A partir de mañana!",
  //       noImageMode: "Oculte la imagen del país para un mayor desafío.",
  //       rotationMode: "Gira la imagen del país aleatoriamente.",
  //     },
  //     stats: {
  //       title: "Estadísticas",
  //       played: "Jugadas",
  //       win: "Ganadas %",
  //       currentStreak: "Serie Actual",
  //       maxStreak: "Serie Max",
  //       averageBestDistance: "Mejor distancia media",
  //       guessDistribution: "Distribución de aciertos:",
  //     },
  //     buyMeACoffee: "Ofrézcame un ☕ !",
  //   },
  // },
  // eu: {
  //   translation: {
  //     placeholder: "Estatua, herrialdeak...",
  //     guess: "Asmatu",
  //     share: "Elkarbanatu",
  //     showOnline: "👀 Google Maps-en",
  //     welldone: "Ongi egina !",
  //     unknownCountry: "Estatu ezezaguna !",
  //     copy: "Emaitzak arbelean kopiatuta !",
  //     showCountry: "🗺️ Erakutsi mapan !",
  //     cancelRotation: "🌀 Ezeztatu errotazioa",
  //     settings: {
  //       title: "Aukerak",
  //       distanceUnit: "Distantzia unitateak",
  //       theme: "Gaia",
  //       difficultyModifiers: "Zailtasun aldagaiak",
  //       startingNextDay: "Aldaketak bihartik aurrera ikusgai!",
  //       noImageMode: "Ezkutatu herriaren irudia zailagoa egiteko.",
  //       rotationMode: "Errotatu ausaz herrialdearen irudia.",
  //     },
  //     stats: {
  //       title: "Estatistikak",
  //       played: "Jokatuta",
  //       win: "Irabaziak %",
  //       currentStreak: "Uneko seriea",
  //       maxStreak: "Serie Max",
  //       averageBestDistance: "Batazbesteko distantzia onenea",
  //       guessDistribution: "Asmatze banaketa:",
  //     },
  //     buyMeACoffee: "☕ bat eskaini iezaidazu !",
  //   },
  // },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
  });

export default i18n;
