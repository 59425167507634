import React from "react";

export function Warbl() {
  return (
    <span className="font-bold">
      <span className="inline-block">
        <img
          className={"h-3 w-4 dark:invert"}
          alt="Flag logo"
          src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
        />
      </span>
      <span className="text-black-600">Warbl</span>
    </span>
  );
}
