import {
  generateSquareCharacters,
  getArtistEmoji,
  getDecadeEmoji,
  getGenreEmoji,
} from "../domain/audio";
import { Guess } from "../domain/guess";
import { Track } from "../domain/tracks";
import React, { useCallback, useEffect, useState } from "react";
import { SettingsData } from "../hooks/useSettings";
import { Twemoji } from "react-emoji-render";

const SQUARE_ANIMATION_LENGTH = 250;
type AnimationState = "NOT_STARTED" | "RUNNING" | "ENDED";

interface GuessRowProps {
  guess?: Guess;
  track?: Track;
  settingsData: SettingsData;
  trackInputRef?: React.RefObject<HTMLInputElement>;
}

export function GuessRow({
  guess,
  track,
  settingsData,
  trackInputRef,
}: GuessRowProps) {
  const { theme } = settingsData;
  const score =
    guess != null
      ? guess.score.title +
        guess.score.artist +
        guess.score.genre +
        guess.score.decade
      : 0;
  const squares = generateSquareCharacters(score, theme);

  const [animationState, setAnimationState] =
    useState<AnimationState>("NOT_STARTED");

  useEffect(() => {
    setAnimationState("NOT_STARTED");

    if (guess == null) {
      return;
    }

    setAnimationState("RUNNING");
    const timeout = setTimeout(() => {
      setAnimationState("ENDED");
    }, SQUARE_ANIMATION_LENGTH * 6);

    return () => {
      clearTimeout(timeout);
    };
  }, [guess]);

  const handleClickOnEmptyRow = useCallback(() => {
    if (trackInputRef?.current != null) {
      trackInputRef?.current.focus();
    }
  }, [trackInputRef]);

  switch (animationState) {
    case "NOT_STARTED":
      return (
        <div
          onClick={handleClickOnEmptyRow}
          className={`col-span-12 h-8 rounded-md bg-gray-200 dark:bg-slate-600`}
        />
      );
    case "RUNNING":
      return (
        <>
          <div
            className={`flex rounded-md text-2xl w-full justify-evenly items-center col-span-12 border-2 h-8`}
          >
            {squares.map((character, index) => (
              <div
                key={index}
                className="opacity-0 animate-reveal"
                style={{
                  animationDelay: `${SQUARE_ANIMATION_LENGTH * index}ms`,
                }}
              >
                <Twemoji text={character} />
              </div>
            ))}
          </div>
        </>
      );
    case "ENDED":
      return (
        <>
          <div
            className={
              score === 100
                ? "flex items-center rounded-md border-2 border-green-600 bg-green-200 dark:text-slate-700 h-8 col-span-5 animate-reveal"
                : "flex items-center rounded-md border-2 h-8 col-span-5 animate-reveal"
            }
          >
            <p className="text-ellipsis overflow-hidden whitespace-nowrap">
              &nbsp;
              {guess?.title.substring(0, guess?.title.lastIndexOf("-") - 1)}
            </p>
          </div>
          <div
            className={
              score === 100
                ? "flex items-center rounded-md border-2 border-green-600 bg-green-200 dark:text-slate-700 h-8 col-span-4 animate-reveal"
                : "flex items-center rounded-md border-2 h-8 col-span-4 animate-reveal"
            }
          >
            <p className="text-ellipsis overflow-hidden whitespace-nowrap">
              {guess != null
                ? getArtistEmoji(guess) + " " + guess?.artist
                : "X"}
            </p>
          </div>
          <div
            className={
              score === 100
                ? "flex items-center rounded-md justify-center border-2 border-green-600 bg-green-200 dark:text-slate-700 h-8 col-span-2 animate-reveal"
                : "flex items-center rounded-md justify-center border-2 h-8 col-span-2 animate-reveal"
            }
          >
            <p className="text-ellipsis overflow-hidden whitespace-nowrap">
              {guess != null ? getGenreEmoji(guess) : "X"}
            </p>
          </div>
          <div
            className={
              score === 100
                ? "flex items-center rounded-md justify-center border-2 border-green-600 bg-green-200 dark:text-slate-700 h-8 col-span-1 animate-reveal"
                : "flex items-center rounded-md justify-center border-2 h-8 col-span-1 animate-reveal"
            }
          >
            <p className="text-ellipsis overflow-hidden whitespace-nowrap">
              {guess != null ? getDecadeEmoji(guess) : "X"}
            </p>
          </div>
        </>
      );
  }
}
