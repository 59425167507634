import * as Tone from "tone";
import { Guess } from "./guess";
import { Track } from "./tracks";

export const player = new Tone.Player().toDestination();

export interface Score {
  title: number;
  artist: number;
  genre: number;
  decade: number;
}

export function getScore(guess: Track, track: Track) {
  const score: Score = {
    title: guess.title === track.title ? 20 : 0,
    artist: guess.artist === track.artist ? 40 : 0,
    genre: guess.genre === track.genre ? 20 : 0,
    decade: guess.release_date[2] === track.release_date[2] ? 20 : 0,
  };
  return score;
}

export function getWinEmoji(guess: Guess) {
  return guess.score.title === 20 ? "✅" : "❌";
}

export function getArtistEmoji(guess: Guess) {
  return guess.score.artist === 40 ? "🎵" : "❌";
}

export function getGenreEmoji(guess: Guess) {
  return guess.score.genre === 20 ? "🎵" : "❌";
}

export function getDecadeEmoji(guess: Guess) {
  return guess.score.decade === 20 ? "🎵" : "❌";
}

export function generateSquareCharacters(
  proximity: number,
  theme: "light" | "dark"
): string[] {
  const characters = new Array<string>(5);
  const greenSquareCount = Math.floor(proximity / 20);
  const yellowSquareCount = proximity - greenSquareCount * 20 >= 10 ? 1 : 0;

  characters.fill(theme === "light" ? "🎵" : "🟩", 0, greenSquareCount);
  characters.fill("🟨", greenSquareCount, greenSquareCount + yellowSquareCount);
  characters.fill("❌", greenSquareCount + yellowSquareCount);

  return characters;
}

export function generateShareCharacters(
  scorenum: number,
  theme: "light" | "dark"
): string[] {
  return scorenum === 100
    ? ["🎶", "🎶", "🎶", "🎶", "🎉"]
    : scorenum >= 80
    ? ["🎵", "🎵", "🎵", "🎵", "❌"]
    : scorenum >= 60
    ? ["🎵", "🎵", "🎵", "❌", "❌"]
    : scorenum >= 40
    ? ["🎵", "🎵", "❌", "❌", "❌"]
    : scorenum >= 20
    ? ["🎵", "❌", "❌", "❌", "❌"]
    : ["❌", "❌", "❌", "❌", "❌"];
}
