import { Guesses } from "../Guesses";
import { Panel } from "./Panel";
import React from "react";
import { Warbl } from "../Warbl";
import { SettingsData } from "../../hooks/useSettings";
import { Twemoji } from "react-emoji-render";

interface InfosProps {
  isOpen: boolean;
  close: () => void;
  settingsData: SettingsData;
}

export function Infos({ isOpen, close, settingsData }: InfosProps) {
  return (
    <Panel title="How to play" isOpen={isOpen} close={close}>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div>
          Guess the <Warbl /> in 6 guesses. Each daily <Warbl /> is a famous
          song in reverse!
        </div>
        <div>
          Choose each guess from some of the most famous songs and tracks of all
          time. All copyright for the songs to these legends of music.
        </div>
        <div>
          After each guess, you will see whether it&apos;s the correct artist,
          genre and decade. If you get the correct song title you win!
        </div>
        <div>
          A new <Warbl /> will be available every day!
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div className="font-bold">Examples</div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                title: "Take on Me - a-ha",
                artist: "a-ha",
                genre: "Pop",
                decade: "80s",
                score: {
                  title: 0,
                  artist: 0,
                  genre: 0,
                  decade: 20,
                },
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Your guess <span className="font-bold">Take on Me</span> by
            <span className="font-bold"> ah-a</span> is the right decade but not
            the right artist or genre.
          </div>
        </div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                title: "Black Velvet - Alannah Myles",
                artist: "Alannah Myles",
                genre: "Pop",
                decade: "80s",
                score: {
                  title: 0,
                  artist: 0,
                  genre: 20,
                  decade: 20,
                },
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Your second guess <span className="font-bold">Black Velvet</span> by
            <span className="font-bold"> Alannah Myles</span> is getting better!
            The genre and decade are now right.
          </div>
        </div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                title: "We Built This City - Starship",
                artist: "Starship",
                genre: "Rock",
                decade: "80s",
                score: {
                  title: 20,
                  artist: 40,
                  genre: 20,
                  decade: 20,
                },
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            The next guess <span className="font-bold">We Built This City</span>{" "}
            by <span className="font-bold"> Starship</span> is correct! Good
            times!!{" "}
            <Twemoji text="🎉" options={{ className: "inline-block" }} />
          </div>
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3 font-bold">
        A new <Warbl /> will be available every day!
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div className="font-bold">Any question or suggestion?</div>
        <div>
          <a
            className="underline"
            href="https://twitter.com/intent/tweet?screen_name=warbl0"
          >
            <span className="inline-block">
              <img
                className={"h-3 w-4 dark:invert"}
                alt="Twitter logo"
                src={"./icons/iconmonstr-twitter-1.svg"}
              />
            </span>
            Tweet us @warbl0!
          </a>
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <Warbl /> was my wife&apos;s amazing idea, the layout has been inspired
        by{" "}
        <a
          className="underline"
          href="https://www.powerlanguage.co.uk/wordle/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordle
        </a>{" "}
        created by{" "}
        <a
          className="underline"
          href="https://twitter.com/powerlanguish"
          target="_blank"
          rel="noopener noreferrer"
        >
          Josh Wardle (@powerlanguish)
        </a>
        .
      </div>
    </Panel>
  );
}
