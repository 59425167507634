export const trackTitlesWithPreview = [
  "Move on Up",
  "Edge of Seventeen",
  "The Power Of Love",
  "Do Your Thing",
  "Wind Beneath My Wings",
  "Feel Good Inc.",
  "DARE",
  "We All Stand Together",
  "Raspberry Beret",
  "Price Tag",
  "Last Friday Night (T.G.I.F.)",
  "If I Were a Boy",
  "Shackles (Praise You)",
  "Sweet Child O' Mine",
  "Higher And Higher",
  "Joyride",
  "Rehab",
  "Tragedy",
  "YMCA",
  "Radio Ga Ga",
  "Under Pressure",
  "My Sharona",
  "Push The Button",
  "Jenny from the Block",
  "The Wild Boys",
  "Son Of A Preacher Man",
  "Le Freak",
  "Stars",
  "Dreams",
  "Yellow",
  "Bad Day",
  "Last Request",
  "Young Hearts Run Free",
  "Kiss",
  "West End Girls",
  "Running Up That Hill",
  "Soul Man",
  "Baker Street",
  "Don't Start Now",
  "I Want to Know What Love Is",
  "You're so Vain",
  "Go Your Own Way",
  "Just the Way You Are",
  "Love Will Tear Us Apart",
  "This Charming Man",
  "You Can't Hurry Love",
  "Once in a Lifetime",
  "I'll Be There",
  "Cannonball",
  "Blue Monday '88",
  "She Drives Me Crazy",
  "Sitting Down Here",
  "Rather Be",
  "Daydream Believer",
  "Smile",
  "I Feel for You",
  "Dancing In the Dark",
  "Get Lucky",
  "Bitter Sweet Symphony",
  "Lost in Music",
  "I'm Outta Love",
  "Who Let The Dogs Out",
  "Nineteen",
  "Call Me",
  "Another Night",
  "Silence (feat. Sarah McLachlan)",
  "Ecuador",
  "Halo",
  "Bring Me To Life",
  "Jar of Hearts",
  "Shut Up And Drive",
  "Don't Stop Me Now",
  "All You Need Is Love",
  "Firestarter",
  "Gangnam Style",
  "Linger",
  "Maria Maria",
  "Wonderwall",
  "Enter Sandman",
  "All Star",
  "I'll Be Missing You",
  "All The Small Things",
  "Return of the Mack",
  "Ironic",
  "Save Tonight",
  "Pretty Fly (For A White Guy)",
  "Lovefool",
  "The Logical Song",
  "The Final Countdown",
  "All The Things She Said",
  "Smoke on the Water",
  "Girls Just Want to Have Fun",
  "I Love Rock 'N Roll",
  "Back In Black",
  "Uptown Girl",
  "Tainted Love",
  "Karma Chameleon",
  "Video Killed The Radio Star",
  "Hips Don't Lie",
  "Back To Black",
  "Family Affair",
  "Umbrella",
  "Breakfast At Tiffany's",
  "Baby, I Love Your Way",
  "Whenever, Wherever",
  "Bye Bye Bye",
  "Here I Go Again",
  "Don't Dream It's Over",
  "That's The Way Love Goes",
  "Up Where We Belong",
  "True Colors",
  "Something Got Me Started",
  "Axel F",
  "Summer In The City",
  "Right Here",
  "Centerfold",
  "Mamma Mia",
];

export interface Track {
  title: string;
  artist: string;
  id: number;
  release_date: string;
  genre: string;
  preview: string;
  cover: string;
}

export const tracks: Track[] = [
  {
    title: "Stand by Me",
    artist: "Ben E. King",
    id: 80728352,
    release_date: "1962-08-20",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-919119b281524b0b9d45303e284e39f6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dcea225fa1c17ad5bab1241bff4d0acf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Space Oddity",
    artist: "David Bowie",
    id: 107465566,
    release_date: "1969-09-25",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-77202dfcef8698e120c5231d245cdf2f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bd4a046f3d8c8895d9aff00a3b7c2df/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dreams",
    artist: "Fleetwood Mac",
    id: 67549188,
    release_date: "1977-02-04",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-23ad912e636b97805ab9766e6f5486f4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/df696ab6f33650f6d8e32dda8adf5264/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "(Sittin' On) the Dock of the Bay",
    artist: "Otis Redding",
    id: 90859071,
    release_date: "1968-01-08",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-583565678527f4c0fedd2d421b491793-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e32d56c61a5914589c57cfd71c5f312e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Say a Little Prayer",
    artist: "Aretha Franklin",
    id: 904747,
    release_date: "1967-10-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a3d1c7458b93d8cbe5b390e0404f62a9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/825419030587ba289ee683382b652136/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hotel California",
    artist: "Eagles",
    id: 68095470,
    release_date: "1977-02-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8af9cfb9a0454481e21989618e7c5779-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/208c9a0b2fcc579be8fb7200c9cc0d4a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stars",
    artist: "Simply Red",
    id: 2551829,
    release_date: "1991-10-24",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fb3477a2dccd5a8fdaaa975a6853c17-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d2cb6d75e25263be723e1b31f9238978/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hungry Like the Wolf",
    artist: "Duran Duran",
    id: 4127465,
    release_date: "1982-09-07",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aaf155c63e00c0a34382615a61129b97-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c3ef1ffaad88a51594e210a1075c2f1c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Against All Odds (Take a Look at Me Now)",
    artist: "Phil Collins",
    id: 134036230,
    release_date: "1981-10-14",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-06f5e92ba64dc2807a18075fa2eddb77-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heroes",
    artist: "David Bowie",
    id: 461043312,
    release_date: "1977-09-29",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71c489fdf2c00071ba9aba8543727d00-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5fb91018679f65199308256be3c584ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Yellow",
    artist: "Coldplay",
    id: 3128096,
    release_date: "2000-07-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ad17e32a652aa4531403e11a66b77ef-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a31bfbc6678856d2cfe10dbb22b8a24/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Thinking out Loud",
    artist: "Ed Sheeran",
    id: 79875064,
    release_date: "2014-06-20",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c5807cf009b065b576c7781973241ef-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b1d763da698c38bde6a526c8220ca0ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fast Car",
    artist: "Tracy Chapman",
    id: 2271563,
    release_date: "1988-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b70ecbdce6aad48d01bad0cab6d0f0f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9990f3c2febfc83c1d7218da7474cb2/250x250-000000-80-0-0.jpg",
    genre: "Folk",
  },
  {
    title: "Wicked Game",
    artist: "Chris Isaak",
    id: 1391362322,
    release_date: "1981-06-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-845c66660e9aace80f26cb8acce74822-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3eeae28ccbcbb62f5cd43565812429e8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Take on Me",
    artist: "a-ha",
    id: 664107,
    release_date: "1985-06-12",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e817b793ead610762756f4f3a48dfcd9-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0ce8977ab98d73bcea00fc838ece034/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy",
    artist: "Gnarls Barkley",
    id: 1235037902,
    release_date: "2007-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9b4a9c15c40881c44e432769941ed47-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c215199c59acb8099cff54d03cf63269/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Best",
    artist: "Tina Turner",
    id: 3167295,
    release_date: "1989-01-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a31ef64abb5addac92367da7ec68fe21-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42024f50dc501575ae920ccf506fa4dd/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Want to Know What Love Is",
    artist: "Foreigner",
    id: 675818,
    release_date: "1984-01-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be34ea8d2d2224219eef1b3a62c24e14-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9ff017e261498ee3b7fd3707763b211/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fool (If You Think It's Over)",
    artist: "Chris Rea",
    id: 424000782,
    release_date: "1988-10-17",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7aa7cd7b9b7e22416e0701067d94c828-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1872b42859b17538573fd64b8c6b93a3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bad Day",
    artist: "Daniel Powter",
    id: 771897,
    release_date: "2005-02-22",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eead6af2083365dd890a44d264834eb4-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0676a2ca21cc85832288b8b43f02482e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Like a Prayer",
    artist: "Madonna",
    id: 4209640,
    release_date: "2009-09-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25482c226fb821a0c118df859dc8d7c2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/195d3d2c1196b20711cfb6ad76e1c2ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Edge of Seventeen",
    artist: "Stevie Nicks",
    id: 135376666,
    release_date: "1976-11-04",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-649b47490aa2b9a8110185beed19886a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ff23019f5d481542c19706995c7980c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Move on Up",
    artist: "Curtis Mayfield",
    id: 115195420,
    release_date: "1970-12-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92db1a86df405d599f8d6c9223895654-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93637763db9f47b7a8f8c1e31ff11c0e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Last Request",
    artist: "Paolo Nutini",
    id: 3657960,
    release_date: "2007-01-30",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c2584db06f643efb3d2e57cff841e07-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f43d26dc4fa2d28279dd3665af1dad83/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Respect",
    artist: "Aretha Franklin",
    id: 904732,
    release_date: "1975-06-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-09922a19e087310a13616e955fb80552-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e9ba9115d0fb62879d1bb672ee67c07/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're so Vain",
    artist: "Carly Simon",
    id: 688346,
    release_date: "1972-01-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85497dfe10d1c229b3c4450ed23e0a19-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ee8f9b6f3ba4d17c17cf3f6b43b24c85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Go Your Own Way",
    artist: "Fleetwood Mac",
    id: 67549191,
    release_date: "1987-01-29",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94b0f2ca53b19e6e5ccb22b730d536c6-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/df696ab6f33650f6d8e32dda8adf5264/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "How You Remind Me",
    artist: "Nickelback",
    id: 810179,
    release_date: "2001-09-10",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-abb9372d51cf773c9817c8a32ef28a4c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61395db55522768a7a42e085c487d045/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Take It Easy",
    artist: "Eagles",
    id: 68094695,
    release_date: "1972-04-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4956811d065e816988a60e767ad34cb2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b7a45d37330235f99a01a2e1e8106d1f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ain't Nobody",
    artist: "Chaka Khan",
    id: 776220,
    release_date: "1983-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b59804c0cf364ec2ef36bf6e1a0722e1-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/84ecee5f0d598c5d0b5da405f6a3fa90/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Feel It Still",
    artist: "Portugal. The Man",
    id: 143171642,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5be7ed5b78972bf8362a628a0fa9bc6f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f204aa755d4354a8d548334f193fd86/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Don't Start Now",
    artist: "Dua Lipa",
    id: 793163542,
    release_date: "2019-10-31",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9bb595a44d64e7bdbf1a14fa9b2f8879-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2330b746089b5f6f4507cb84cc35d4d3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "A Little Respect",
    artist: "Erasure",
    id: 37210341,
    release_date: "1985-06-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-42f7a2736e344e873f082321ea298c8f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e58e0507ac0a284381b3d52d3f9b4ae3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just the Way You Are",
    artist: "Bruno Mars",
    id: 7084642,
    release_date: "2010-07-20",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-714a5aeb851cd57eb56143a9f7501f20-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fca132eaf0b297e95aca78a2739a0f33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Somewhere in My Heart",
    artist: "Aztec Camera",
    id: 1235037862,
    release_date: "1987-01-18",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fe58a17148bb34eb45689dcb0814eee2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c215199c59acb8099cff54d03cf63269/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Young Hearts Run Free",
    artist: "Candi Staton",
    id: 720737,
    release_date: "1976-02-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0199b1cff120177e7a1f777e96c94228-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a3cad753bd8e46f528d5d339f9f0e57/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Wings",
    artist: "Birdy",
    id: 69466071,
    release_date: "2013-07-29",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88f83bbbb58547db3550aafcf3cf91fd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d24aeaaa8ddb94c9e71b36ab1ac690c/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Golden Brown",
    artist: "The Stranglers",
    id: 3152622,
    release_date: "1981-10-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-832db1bcf0abf673abe381912c7e42b8-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/510f299a5e25a267f0dde2dcfd41d0f1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "We Built This City",
    artist: "Starship",
    id: 602137972,
    release_date: "1985-09-10",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-232990c8f476a53b8e8f1d21456a9a7c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/15cf311226db704fd9925c4362628ac8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pure Shores",
    artist: "All Saints",
    id: 144189832,
    release_date: "2001-03-17",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4df4dfea82c6aa1a4ced151befeb2df7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/495ea4d18925c6874fc42abd0b1907c8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Will Tear Us Apart",
    artist: "Joy Division",
    id: 1235037822,
    release_date: "1981-02-12",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3dafb75a952e5e5d5131331488609ee-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c215199c59acb8099cff54d03cf63269/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Charming Man",
    artist: "The Smiths",
    id: 13776514,
    release_date: "1981-06-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d09c9b9af7180a658dfc029d551e88c6-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57d3a0dd210f59a53b889d2afb20c402/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "You Can't Hurry Love",
    artist: "Phil Collins",
    id: 119121832,
    release_date: "1982-11-05",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed5aae42f04e0fd8e699f1b108a3af9b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ac7ba5cf3b11fabf4e6779e40cfce16d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nothing's Gonna Stop Us Now",
    artist: "Starship",
    id: 602137932,
    release_date: "1991-05-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-55222cd34e7d2fba5afb1744f930a148-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b23cca2bd9985e39cb1909c3cc90cc9d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Groove Is in the Heart",
    artist: "Deee-Lite",
    id: 4099864,
    release_date: "1990-04-19",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-298b2d5caecc9cfab10490644dc719d1-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7bb9cb815727f484385b813037fdb6c2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss from a Rose",
    artist: "Seal",
    id: 730428,
    release_date: "1987-11-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b6f225c283d8fbbcd51dc4a92f5a964-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4c895d110af6fb22aa99c2d2bf995a0d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're Beautiful",
    artist: "James Blunt",
    id: 2659940,
    release_date: "2005-08-08",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c1595ef41a27a8136efcce75b09f5d55-20.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8b701ce9a0c1cdddf43a42638d0610b3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss",
    artist: "Prince",
    id: 664178,
    release_date: "1986-03-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88bda5b7d6476ed5b7b5d4c335958ead-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/07d3890b48303d09025f92ba7b9d67ac/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "True",
    artist: "Spandau Ballet",
    id: 3130319,
    release_date: "1984-12-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9007a8ca87de15e9bd9ba6c93bec1591-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b88d3c3ad23435fb5b8d52038551c1f6/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "It Must Have Been Love",
    artist: "Roxette",
    id: 3098301,
    release_date: "1981-07-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-087ef667466625cc3010ff8036798c77-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04a8fc1d885619f9418aab478cccf881/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Once in a Lifetime",
    artist: "Talking Heads",
    id: 5093672,
    release_date: "1980-10-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ab593fcf9ba97958c3fc0f7108dbf46-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f829d18efbdb8a54fbca7701d5098a9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Can't Get You out of My Head",
    artist: "Kylie Minogue",
    id: 3098277,
    release_date: "2001-10-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a085a375a7bbd76f95758339dc3210cb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/834a7fd1964efa083b6e5bf82b49f406/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "West End Girls",
    artist: "Pet Shop Boys",
    id: 3310512,
    release_date: "1986-06-06",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4b506d168bb3364db29d1b5f6cc3270-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/393d2ce1ba348485b895213e226bd9da/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Pack Up",
    artist: "Eliza Doolittle",
    id: 6719344,
    release_date: "2010-07-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0fc5756038a0a6bc0c89c5ff42c83589-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/db89f647fd33aad7fb446423ecc759d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Shack",
    artist: "The B-52's",
    id: 701763002,
    release_date: "1989-08-19",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9c8fd8a5a38d2e30272577fb13b01aae-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6818c7381d798e48f789709b882a77b4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's My Life ",
    artist: "Talk Talk",
    id: 3257507,
    release_date: "1984-01-15",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d1a46e4d7a6c48f95feb267b7b483ce-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d0f763786b6be3fe0450985f5e0c524/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Running Up That Hill",
    artist: "Kate Bush",
    id: 11616579,
    release_date: "1985-05-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20d97b302bb6209f943e23a2a153300d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f2d90023183010d657b01cba6bd5f19/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'll Be There",
    artist: "Jess Glynne",
    id: 491898322,
    release_date: "2018-05-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0167683656231ba442832c3ef0085fd0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6b50f62715805aecb686fa1a90d1705b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just the Two of Us",
    artist: "Grover Washington, Jr.",
    id: 3085246,
    release_date: "1980-04-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e5ac9c12cacc92be13deb6c063d23ea8-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6d04772ee490586a8771df7c2705c43/250x250-000000-80-0-0.jpg",
    genre: "Soul",
  },
  {
    title: "Drive",
    artist: "The Cars",
    id: 474178182,
    release_date: "1984-03-13",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f6185b2e1e03eb4833d230723eab910a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3c653ace62ec6833849e55970598199/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Breathless",
    artist: "The Corrs",
    id: 661620,
    release_date: "2000-01-01",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b8924a147f8a93efe1933b61308aef1-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/53399d8b50372230690a46d783a83709/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Soul Man",
    artist: "Sam & Dave",
    id: 685250,
    release_date: "1967-06-20",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4e17e6a0c43e93b0cc3240cb892c9cf-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/127d728275f3bacb36c19705ecf9ceb1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Yours",
    artist: "Jason Mraz",
    id: 845909,
    release_date: "2008-05-12",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e558b76b232e85cd3b4197b01b64ffc7-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/14afa8e767482283a4bd57ceae31eb80/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cannonball",
    artist: "Damien Rice",
    id: 904289,
    release_date: "2003-06-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0a584c13095c0db09399b2a07ec19ca-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fb2dfbd954d1bf84405bdafa9a76491/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Brass in Pocket",
    artist: "The Pretenders",
    id: 14636389,
    release_date: "1992-05-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bfce541a2dd34b05775b0f3eeb24ae61-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9eb2fd178dc4c9d53204ee6467cb52cb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When a Man Loves a Woman",
    artist: "Percy Sledge",
    id: 727540,
    release_date: "1991-02-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b38679212ca198a4d4c15bba0a61fe8-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cb9cd3ff4da779845c681baf2241570e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Baker Street",
    artist: "Gerry Rafferty",
    id: 3169189,
    release_date: "1989-07-24",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-12166841d4a1fc6b9fb47a9e1dd87be5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4a40656ade105b7010c58687a14bd06/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Cry to Me",
    artist: "Solomon Burke",
    id: 672732,
    release_date: "1962-05-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c159908b21c898e003830d67691c7535-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/942ac93d77390fcded81ff7783c05b49/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Black Velvet",
    artist: "Alannah Myles",
    id: 674958,
    release_date: "1989-03-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c4d9e614034f334dff4857fa5eec107e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/38793735d9b791ceb15e0679161a9248/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Blue Monday '88",
    artist: "New Order",
    id: 1145114442,
    release_date: "1988-12-06",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-991285cc67a3f43dbf7b3b75a541ec2a-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/36f64eb1dee209e6510f4805a77f2362/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Walking in Memphis",
    artist: "Marc Cohn",
    id: 675175,
    release_date: "1991-01-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84c606e8f63a134e36b79adfcfcb1873-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e93c673f9acf0afa27432ae67dfad76b/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Wish You Were Here",
    artist: "Pink Floyd",
    id: 116914042,
    release_date: "1975-09-15",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f7c1f7c23efa8577cf8610ec75194e11-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4720f991d5806bd8dcf46f9460737cad/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Perfect",
    artist: "Ed Sheeran",
    id: 142986206,
    release_date: "2017-03-03",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4d04fa22ff3cc680cad30eea149cc1d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/000a9228cecfcc7c2093d9cd7bb66447/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Harvest Moon",
    artist: "Neil Young",
    id: 677484,
    release_date: "1992-10-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba95d34546cda7590001327db9692543-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/51e508e0f63b2d4f610d84503fc10467/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Constant Craving",
    artist: "K.D. Lang",
    id: 5414332,
    release_date: "1992-02-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-82995b6c93f151ef4dd10c13736e297f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d3f9b6f6f1836c8362d8f42ef5f37d4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You've Got a Friend",
    artist: "James Taylor",
    id: 2794416,
    release_date: "1970-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ab108a0786bba922d6618deda6f3a5d-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/feb6b5dcb16e2f5e0441c1d3623f8c20/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Knock on Wood",
    artist: "Eddie Floyd",
    id: 690462,
    release_date: "1979-03-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a2a6de0ab37cbda4debeec654c83483a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0932240b16bf7293d82c58e05f5116ca/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "She Drives Me Crazy",
    artist: "Fine Young Cannibals",
    id: 700715042,
    release_date: "1989-02-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-095d34f3762aef22f9b968defc492ee9-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d8f5b3da8a883596aba17a55067e426f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Riptide",
    artist: "Vance Joy",
    id: 70054782,
    release_date: "2013-09-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2150a01983b8283c4a43c614ed2b6de2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/537f1cef9034d1baff03bad5da8213ee/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Sleeping Satellite",
    artist: "Tasmin Archer",
    id: 3115574,
    release_date: "1992-10-19",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0845b43c61366668207cfc6c8c424a43-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b85663f5c0008c8fd0b7892506c4f822/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Sitting Down Here",
    artist: "Lene Marlin",
    id: 3134928,
    release_date: "1999-05-31",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2e2554b1ecb9b9c82e66b6a6616cc2f2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a039abef730d570941f61c50d8b53e0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rather Be",
    artist: "Clean Bandit",
    id: 73982869,
    release_date: "2014-06-23",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d2e261169f82dd2789697fd19ed572b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3193132d50c74a62d1cd419fa170139a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Love Changes (Everything)",
    artist: "Climie Fisher",
    id: 3147082,
    release_date: "1988-03-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-507eda177b001070537ca56b0b3ddb8c-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6ad9e952f6e2d02d54b4b68a2e5c440d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Got You Babe",
    artist: "Sonny & Cher",
    id: 735788,
    release_date: "1965-04-26",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-750099e4fc8d5ddfbb110441af5eef44-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ebbc4b4ee98befdb3c342aef82af73e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Love",
    artist: "Anita Baker",
    id: 717534,
    release_date: "1986-01-01",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4df376049864b67660cceb07a2db4122-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4dfc174b1bb5ce17188129fb2994fb78/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Into the Mystic",
    artist: "Van Morrison",
    id: 71433041,
    release_date: "1970-10-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3babab27947533b5017fee77f7957e74-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f648440c11c75ac519bd74b75f486cb8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wasn't Expecting That",
    artist: "Jamie Lawson",
    id: 103907218,
    release_date: "2015-07-17",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a87f6d40b064edff2fef9cbe023b9210-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0529672ee69ae96207d2967e785e23e3/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Daydream Believer",
    artist: "The Monkees",
    id: 728925,
    release_date: "1968-12-18",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af13deb9955b853252841efe7f53f6f3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ca2f96e8c7bb5c148f18b2d6e065662/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Smile",
    artist: "Lily Allen",
    id: 3153912,
    release_date: "2006-07-17",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b30dcbe1bdcee000103ff764b6caea97-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d6fe228b80ced09fde7666b3d9371567/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Love You Always Forever",
    artist: "Donna Lewis",
    id: 846048,
    release_date: "1996-06-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-150597c754b3113a5e25036f88ac6e14-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/27795c295df90807631e2bffa1da0a73/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Owner of a Lonely Heart",
    artist: "Yes",
    id: 3613215,
    release_date: "1983-06-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3b016badfaf4a15d76b7baeff5ddfe60-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1bdcb776e4d04064cba66bdd494a94ef/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Viva La Vida",
    artist: "Coldplay",
    id: 3157972,
    release_date: "2008-05-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ab0b3c336efc5e72a8eb5f783f383a85-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eede3cd0dc3a5a87c7a5b1085b022e2d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Iris",
    artist: "The Goo Goo Dolls",
    id: 4209117,
    release_date: "1998-03-31",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d75996c9a9971ce9d40d77640f73af50-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82b3dfb072236e94f9d6c8204b0fd82c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Universal",
    artist: "Blur",
    id: 38441521,
    release_date: "1995-09-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-71ce50ca06a129a75caa7e2d3f0a3c84-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f501ee1cff6f7ecdf0d8f2f67cceb4c4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Under the Bridge",
    artist: "Red Hot Chili Peppers",
    id: 724838,
    release_date: "1991-09-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d178c47b6048f48a58b1b7e033981ce-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d7af03204d679ef877b9033d279d8bd/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "A Thousand Years",
    artist: "Christina Perri",
    id: 14495443,
    release_date: "2011-10-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b944ecc72fe487f50fcd2cf9160ad79f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f361f0228d08731b70cb0c996520606/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rise Up",
    artist: "Andra Day",
    id: 105804300,
    release_date: "2015-08-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-14a7261e5cd812bec8dadfaaf32fc913-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/428505622a2bf7bbed8b1d05cdb67b8a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Love Town",
    artist: "Booker Newberry III",
    id: 74785361,
    release_date: "1983-06-06",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cd7508de8b1f6110d7936d6f1c6587f3-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6acb8032280e22494773cc3b08229c29/250x250-000000-80-0-0.jpg",
    genre: "Soul & Funk",
  },
  {
    title: "You're the One for Me",
    artist: "D Train",
    id: 73236419,
    release_date: "1981-11-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c382cb27459adec89d4292d3ad1b773-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/180435dc808834a234c4e8bca7e4a48e/250x250-000000-80-0-0.jpg",
    genre: "Soul",
  },
  {
    title: "Forget Me Nots",
    artist: "Patrice Rushen",
    id: 434614222,
    release_date: "1982-01-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-284b8ac6611e87fb57b644a2f313daaa-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c8189301e853ab1debddc16e2bce59ff/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Imagination",
    artist: "Belouis Some",
    id: 3873128,
    release_date: "1985-06-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6d8e339cb937819d987840c31e22c153-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f0e862ec9a60ba799292fb0e0ab5798/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Native Son",
    artist: "Bryan Adams",
    id: 1564430,
    release_date: "1988-11-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28b5b1b1e0857f1da7de40efb0034f5d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/665789e3447d33ef1f3af71ff4d44ad1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Feel for You",
    artist: "Chaka Khan",
    id: 685227,
    release_date: "1984-04-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68c0ce584f4cc7368514fd8b782ef8f5-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1b35699645d63299c0979e679d7f4ee2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "(Feels Like) Heaven",
    artist: "Fiction Factory",
    id: 5404615,
    release_date: "1984-02-26",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8c6a13c163aadda377d5d09db9ebb335-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dancing In the Dark",
    artist: "Bruce Springsteen",
    id: 15586246,
    release_date: "1984-06-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b87c84eba8d82a62d9bf28c5e8422e68-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106f651bf559796879b90e83c50b3e96/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hysteria",
    artist: "Def Leppard",
    id: 447552872,
    release_date: "1987-01-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c894a5672f87659f5c8e7f45019a4874-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86d5af2077f5b6ef18fd0ca158a91134/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Stop Believin'",
    artist: "Journey",
    id: 534462,
    release_date: "1988-11-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b5c3b31a47fa4f795db48e5b1b2c2d56-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b97441032653a9f03f3d25a5dc2f459f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One More Time",
    artist: "Daft Punk",
    id: 3135553,
    release_date: "2005-01-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e77d23e0c8ed7567a507a6d1b6a9ca1b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e018122cb56986277102d2041a592c8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Get Lucky",
    artist: "Daft Punk",
    id: 67238735,
    release_date: "2013-05-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-853d19a12a694ccc74b2501acd802500-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b298094528702627877720d0be4448b5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Don't Feel Like Dancin'",
    artist: "Scissor Sisters",
    id: 2315311,
    release_date: "2006-07-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8046f3d34f73e4fd5665f89dc7d18ff0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0150dc66d11638cd0771642d01904ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dreamer",
    artist: "Livin' Joy",
    id: 117106930,
    release_date: "1994-01-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-032b20a5886396551ef073486a6058e9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ef022d3d20ed7fa43500a08194768e9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "U Sure Do ",
    artist: "Strike",
    id: 129736034,
    release_date: "1994-08-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d0d11266bef63f6a32339d51a216f11c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0fc707635fcafc750d905d9bd9560ad9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Celebration",
    artist: "Kool & The Gang",
    id: 906583,
    release_date: "1977-09-10",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-252ace5c2cb058e6479485164dd22c0c-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dae9c863ba27686af441666135693de2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Come Down",
    artist: "Evelyn Champagne King",
    id: 5404581,
    release_date: "1982-02-26",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-904a80885f6082dfec67789af6c54a0c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lets Groove",
    artist: "Earth, Wind & Fire",
    id: 430578532,
    release_date: "1977-11-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aab6413994118b61630a9b830831e802-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/389e3012c41b48aa493020973fa483fb/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Free",
    artist: "Ultra Nate",
    id: 86921769,
    release_date: "2014-10-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c0b93ffc391ec44b1c4630dbfcd1f561-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/12c9401626942f3db294bd916332bf75/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Push The Feeling On",
    artist: "Nightcrawlers",
    id: 105353318,
    release_date: "1992-08-21",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3111f8eec4820558d1d22b05f85a526c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/48bed431be72f9e0e68830f13051254b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Bitter Sweet Symphony",
    artist: "The Verve",
    id: 432946922,
    release_date: "1997-12-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-097836a8a6e68d3316046cf1d0dcbf34-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/819e389b479ee5e480b188f45445b929/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "And the Beat Goes On",
    artist: "The Whispers",
    id: 131212454,
    release_date: "1980-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74fc7254eda3b1be43785714bed70fa1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1576b2e2f508ed996238bc9913c0fed0/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ain't No Stoppin' Us Now",
    artist: "McFadden & Whitehead",
    id: 4760904,
    release_date: "1979-12-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1efe49a2b45a558eefee1a5eed7ea64-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5317a8ed11b80200085a5300f4bf8ebb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Got to Be Real",
    artist: "Cheryl Lynn",
    id: 68075550,
    release_date: "1978-06-21",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba6c22196f0de572729a88edabdded35-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6033d76cdc6c1c2ebe48754c6a719b69/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Can't Wait",
    artist: "Nu Shooz",
    id: 71807622,
    release_date: "1986-05-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0f5d0d537eeb89a77de02689e0fa122-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75b5b2eca192562caf0a85f65f12d371/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Never Give up on a Good Thing",
    artist: "George Benson",
    id: 95964494,
    release_date: "1981-02-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20f054ed6667eb1fe1d474a646924448-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7d27b5c59a8da92ccf7d24d82aceb0f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Can Make You Feel Good",
    artist: "Shalamar",
    id: 5448334,
    release_date: "1982-01-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d1d945e204872aae8ad562becbf94c26-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3ebc84e231d71d3807a66dc7658a9c4/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Give Me the Reason",
    artist: "Luther Vandross",
    id: 15176244,
    release_date: "1986-09-19",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-501cb4fe04586fe852b72bd2f3dea6ce-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0da8b7dd2dcf02359cb82f30eead27a1/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Lost in Music",
    artist: "Sister Sledge",
    id: 846328,
    release_date: "1972-08-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7258174843c3ec7819b9a01df3929c4f-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b384580ff1e772c40db28546a9811d32/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "We Are Family",
    artist: "Sister Sledge",
    id: 846327,
    release_date: "1972-08-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6b05c20abf869b9e739551ad0305bcf-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b384580ff1e772c40db28546a9811d32/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Can You Feel It",
    artist: "The Jacksons",
    id: 5404550,
    release_date: "1980-02-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b87577c5372e2278c26048db92b7fda7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Not That Kind",
    artist: "Anastacia",
    id: 833621,
    release_date: "2000-05-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a7ca9a09cbaa6d345db9a0da124b0968-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d468c03b8c03bf5edb8c310a7dd56b8c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Outta Love",
    artist: "Anastacia",
    id: 833622,
    release_date: "2000-05-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eea475a0e8c70d276bfb15623a733b15-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d468c03b8c03bf5edb8c310a7dd56b8c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm So Excited",
    artist: "The Pointer Sisters",
    id: 546757,
    release_date: "1982-01-01",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0735eb29acc57c39728b521d0daf54ce-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe6400d84e249e3800f3df2da1e91958/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good Times",
    artist: "Chic",
    id: 6614292,
    release_date: "1979-10-05",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5c2c096fc9c666d34c2876aa645f0fc8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/868b1a467d78ebe197444b293b52b10d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Gonna Be (500 Miles)",
    artist: "The Proclaimers",
    id: 3109247,
    release_date: "1987-08-11",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5cf464a29ee9984a7079427ed1c53447-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f22a178ac1d33dc7abe7564f0404a6c8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Reach Out I'll Be There",
    artist: "Four Tops",
    id: 909611,
    release_date: "1966-06-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba131b95d8265885ecdb9c6833e4c746-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18b4eeac942cf8f4e4fee33fd954ff17/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boogie Wonderland",
    artist: "Earth, Wind & Fire",
    id: 1277771,
    release_date: "1978-07-25",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b20ff68f25b681ba1678f03aa724902-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7fe1f8e4c7ea6bf2b1f99543d3a8c35e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Who Let The Dogs Out",
    artist: "Baha Men",
    id: 3389258,
    release_date: "2006-03-07",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9bb5bc163fc483e3496f92ef88b20032-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c811e7975cf933fdb0b17a6426dcc01/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hey, Little Girl",
    artist: "Icehouse",
    id: 13213908,
    release_date: "1982-08-26",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a075d91d751c69831c0d510ebad6e20-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c0ec5d3e566373109767afce9f9879eb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "What Is Love?",
    artist: "Howard Jones",
    id: 367292071,
    release_date: "1983-06-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3deded5081d94f0a096faf67572655a4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4bace238d73b2329d4f6596a71ee6aca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Killer / Papa Was a Rollin' Stone",
    artist: "George Michael",
    id: 16433503,
    release_date: "1998-01-30",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0609e89277d9dcb5c10b9a45136e8a47-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/549a1f50cd5903e2f42e3253814f8b33/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here Comes the Rain Again",
    artist: "Eurythmics",
    id: 1022289,
    release_date: "1991-03-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a5b5969f6e6661e38f2017b11dc1841e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81061a21fd646f5bec5dcfe32684385b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thorn In My Side",
    artist: "Eurythmics",
    id: 1022300,
    release_date: "1991-03-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d214ec732f051fd2e50e5d84c8de83b0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/81061a21fd646f5bec5dcfe32684385b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Is A Stranger",
    artist: "Eurythmics",
    id: 576086,
    release_date: "1991-11-04",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d03738f6a80d353e0c780c2677210bee-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/160c2f2740b5043d609af68003d01eb0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What's Love Got to Do with It",
    artist: "Tina Turner",
    id: 3120266,
    release_date: "1984-02-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-083ffa1c986d0da6a79299a09fd68691-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b5022390f35e035d14edd5e0d6cfb92/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Sometimes",
    artist: "Erasure",
    id: 143041970,
    release_date: "1987-10-20",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87cd1b8510115ce2baece05938a6e5dc-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc1c2bd258e623943d223ab873674d82/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "We Don't Have To Take Our Clothes Off",
    artist: "Jermaine Stewart",
    id: 9968997,
    release_date: "1986-02-25",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e15b496715c320f0500a0d9a3bbf015b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7bfc7009e751d43d96f7e8b1aabf7e8f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Nineteen",
    artist: "Paul Hardcastle",
    id: 132042578,
    release_date: "1985-09-12",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c72471c2d0f401a9208f268f8c796270-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6aeffa2d5c980f71a2e3efc5303ec6df/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bad",
    artist: "Michael Jackson",
    id: 59509531,
    release_date: "1982-09-14",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6af9d84fbe64703d53cb09674107764c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Can't Get Enough",
    artist: "Depeche Mode",
    id: 68511564,
    release_date: "1981-10-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-845b21514d119725845ce6a5b2d996e2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9ec9c75ccce73408e769d18f382669fd/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pretty Green Eyes",
    artist: "Ultrabeat",
    id: 379139391,
    release_date: "2007-07-07",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74d81a62e859f5c077aeb803d688c0af-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bbf94dc2b6784a263c3c8ef611f0afcf/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Dakota",
    artist: "Stereophonics",
    id: 2420458,
    release_date: "2008-12-23",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-41617eb5aae34dd3be513cf189033920-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0d210da789d5468aa7f4322e4c4941b8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Go",
    artist: "Yazoo",
    id: 143039072,
    release_date: "1982-01-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16a9ba6784173785d576d2da516f10b2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de6b4c328de177e3331e8e2826305c0f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All Around the World",
    artist: "Lisa Stansfield",
    id: 1019524,
    release_date: "1999-12-31",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f0f4466060fd296ee615706186528980-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b93da8c24fe0a6942d10caec26725afd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Have Nothing",
    artist: "Whitney Houston",
    id: 5363365,
    release_date: "1992-02-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d4523e57206bbaa14db2c83651f97db2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9f963b239113b1f92c39d10a051d95e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Call Me",
    artist: "Blondie",
    id: 3104486,
    release_date: "1980-03-03",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4f9787e48485c475ff7dc59c5cbb962e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cdc9d8ba7d6f9d8c40c5389bb4577409/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Sun Always Shines on T.V.",
    artist: "a-ha",
    id: 664115,
    release_date: "1985-06-12",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-736d5dc2597f1abc7a8228570244fd01-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e0ce8977ab98d73bcea00fc838ece034/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Another Night",
    artist: "Real McCoy",
    id: 1042543,
    release_date: "1995-04-10",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28e1a18fd03df6ce31723fd649a9dcdc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8cdc05b609232d463417238a6dc09e89/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heaven",
    artist: "DJ Sammy",
    id: 69420264,
    release_date: "2002-02-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d370a760b13a4e18ead0b86a4a099789-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62a81585292c53acc048eb02b3fd7b62/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "9 PM - Till I come",
    artist: "ATB",
    id: 61639668,
    release_date: "1999-08-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa5c8d9d0204ff46f69adf82ee7eaa75-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dbb747abd287e0dead5c68ef6c3e2bd8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Silence (feat. Sarah McLachlan)",
    artist: "Delerium",
    id: 85276490,
    release_date: "1997-09-28",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6822a49b80356b12ebea6204366880be-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b37a343d30a8a723b22e0d45896e05f9/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Something",
    artist: "Lasgo",
    id: 60831484,
    release_date: "2011-09-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1022552f6381a36bc411ce7587fb75f2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e4de1e0dba6c4fcfc525f630bac91f19/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Forever",
    artist: "Super3",
    id: 414650452,
    release_date: "2001-09-29",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d13f959288f617fe4fe4789d2f705d7b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/79a92923ada47f26ac6b8e1c3d4ff0f4/250x250-000000-80-0-0.jpg",
    genre: "Kids",
  },
  {
    title: "L'Amour Toujours",
    artist: "Gigi D'Agostino",
    id: 35782811,
    release_date: "1999-01-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bca5828f633d6633f7a51106db6c1f66-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e19251a60eeae5b1943861dffc23ea5e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Love & Pride",
    artist: "King",
    id: 2311058,
    release_date: "1998-02-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16ab1668c0441aa479ad8bc3d87bfe19-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be74b8af8f2677d8ff8f923a38b0dfb7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Encore Une Fois",
    artist: "Sash!",
    id: 133949042,
    release_date: "1996-03-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7da797c83c315d940e52c4b4b030d0ce-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28b63337235b5d9d1633e260e501c04c/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Ecuador",
    artist: "Sash!",
    id: 133134750,
    release_date: "1997-03-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-47ca01bd3d8350f388c9f23642baaadd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6dc2524744ef735f7fe0471c0c95f5b2/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Be My Lover",
    artist: "La Bouche",
    id: 4096754,
    release_date: "1995-08-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-184efc586336cb6491988a262ff3f0a5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02f880e927fe2d0ea5c5c234ef0bd050/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "For An Angel",
    artist: "Paul van Dyk",
    id: 60827150,
    release_date: "1998-10-28",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b55a0c4986990aaa43c36179ea36a1a0-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f16bad5d5de8b2e243f8e7470b0ef91/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Party Rock Anthem",
    artist: "LMFAO",
    id: 12452085,
    release_date: "2011-07-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3ef1780765879efb593ab8dcd122bf4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f66f698240e7bd03385f89773bd3148/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sexy And I Know It",
    artist: "LMFAO",
    id: 12452086,
    release_date: "2011-07-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3be73fb4ad7345466f3c44f00849afc0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f66f698240e7bd03385f89773bd3148/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sorry For Party Rocking",
    artist: "LMFAO",
    id: 12452084,
    release_date: "2011-07-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-644ff92bb8d578e58598ad2353564931-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f66f698240e7bd03385f89773bd3148/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Halo",
    artist: "Beyoncé",
    id: 2485108,
    release_date: "2008-11-14",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0bba1ec9f872bfa1d167161fb42bdc7d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0450a010b5a825194d7ca00b3067ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Flames",
    artist: "David Guetta",
    id: 473784012,
    release_date: "2018-03-22",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a3707ffb8d8fdc30df8fbf43dcbc782-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00ba194ad2417b1cc6d79a34113995cb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Alive",
    artist: "Sia",
    id: 107980710,
    release_date: "2015-09-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec9133f8fba10bb30556620aa12ee51c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/36ef26001118282b59659568f267bc36/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Smalltown Boy",
    artist: "Bronski Beat",
    id: 428735732,
    release_date: "1982-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b66ff0839b24ee7e8b84f42134ef2575-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ffbbe6dfa1ff0d6ff551379a496b8f85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy 'Bout My Baby",
    artist: "Randy Newman",
    id: 123820402,
    release_date: "1963-10-11",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3244036b73c1ba8acbd5e4fd0e1c73e0-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d7ac975bb038b063abb2cc61b23791a3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cry for You",
    artist: "September",
    id: 15543742,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6ef3280a5b3b775f8da2c3455ab66f07-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bacb7ec2fe6b57f1af8f9a9efc083b68/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Rhythm of the Night",
    artist: "Corona",
    id: 61723285,
    release_date: "1995-01-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f97416643cdf419ed612e60d8574a4ef-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cb7dc8ffabfb85536cbae5ca1801f39b/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "The Key, The Secret",
    artist: "Urban Cookie Collective",
    id: 12159134,
    release_date: "1996-07-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-020354f403e87b56684efd7fc556ec8b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57ffff16dc7fff50b677277367f3fb6f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Bring Me To Life",
    artist: "Evanescence",
    id: 80274512,
    release_date: "2003-06-24",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cae4a814e972d68fe00695271871ef40-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/56c02d3764f5a078ceecd27b64b7d789/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Jar of Hearts",
    artist: "Christina Perri",
    id: 12343340,
    release_date: "2010-07-27",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c1694e8cfa5424dcbeb3f8fbc983030b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2cf468fd0556a7e87d10640ebb270587/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Stop The Music",
    artist: "Rihanna",
    id: 925108,
    release_date: "2008-06-17",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5581559e0c31b7f329ded7814d50ed52-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Only Girl (In The World)",
    artist: "Rihanna",
    id: 13529563,
    release_date: "2011-08-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d20ea30267f84d42b66c6aa61c3aa43c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3a12c64bb52a167944783878ffe41f02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shut Up And Drive",
    artist: "Rihanna",
    id: 925110,
    release_date: "2008-06-17",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0e2f4196ee369ad3328a5f6b0b3e8f8b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Airplanes",
    artist: "B.o.B",
    id: 6461684,
    release_date: "2010-04-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0016d9da7194d83c1b4992bafb120ef0-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1922ce037df0dd576c008bb626320303/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Good Times",
    artist: "Roll Deep",
    id: 6431207,
    release_date: "2010-04-23",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f8e809183253c0e08a54ba3a518d24b9-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/99fad9915c25538bd431b3b3d96260fc/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Jai Ho! (You Are My Destiny)",
    artist: "A. R. Rahman",
    id: 2947119,
    release_date: "2009-02-23",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cf7449dce83b1ae9c037efd50d22dd85-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e6bfa2ee147854dc35fdc5dd4950a084/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Better in Time",
    artist: "Leona Lewis",
    id: 13144592,
    release_date: "2008-03-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6d486e9895a3f9bb09da7fc7f470a92b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/53a797aae2e6b3894743c390d57387ca/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Stereo Love",
    artist: "Edward Maya",
    id: 6310384,
    release_date: "2010-05-09",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b6d7a44a4e0dd6dfe5cc770934abc36e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2ad30b3a0b496dd1e7b126b5b213e2cb/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Infinity 2008",
    artist: "Guru Josh Project",
    id: 61686264,
    release_date: "2008-06-06",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b8367f9c269cc2c257fa9d27e0570339-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/19ebbbef82394515787864d7fc480170/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Stop Me Now",
    artist: "Queen",
    id: 12209331,
    release_date: "1979-06-27",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5a9b2da5acb610170196eb5872c7d2ab-17.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb22a75aa1aafc992230abd0bd79faef/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Under Pressure",
    artist: "Queen",
    id: 3155488,
    release_date: "1981-12-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fd0525f2f9cbf39468204210e8c1610-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a1daf96211c7d0d662eab97b5e6e337/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Radio Ga Ga",
    artist: "Queen",
    id: 7868649,
    release_date: "1984-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68c4208e44f727d6114d5e24ab25f701-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa63381faefb51fba501498bc836765a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I Want To Break Free",
    artist: "Queen",
    id: 7868651,
    release_date: "1984-01-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c6099e818548df3284b02303905754d7-18.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa63381faefb51fba501498bc836765a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Eye of the Tiger",
    artist: "Survivor",
    id: 576431,
    release_date: "1986-02-28",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-10457ff7a729df6d66df6f19ab135a4d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e66b5d3a40f69690c1633afb73cc590c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Know Much",
    artist: "Linda Ronstadt",
    id: 7125589,
    release_date: "1986-12-19",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-022dfcfe3cb58134b286edd10b01ac93-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/095eaaa2dec76bd2b120d10edc708b37/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Unchained Melody",
    artist: "The Righteous Brothers",
    id: 885544,
    release_date: "1965-06-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00591b3adbdd0ee9879ebf9252d41316-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4d10d999f93483e2ea1a4e3496d8d72e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Name Is",
    artist: "Eminem",
    id: 1109729,
    release_date: "2005-11-21",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b410ccaf3c000eeaa74025f558892638-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e2b36a9fda865cb2e9ed1476b6291a7d/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "(Everything I Do) I Do It For You",
    artist: "Bryan Adams",
    id: 2174912,
    release_date: "1991-11-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-415b47d4c102d53e681d5a6866fd46c5-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/48e10e9ff0d2fa8cf09d9cd746a14fa2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer Of '69",
    artist: "Bryan Adams",
    id: 88902741,
    release_date: "1984-11-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a39fc1a77b3a221c68103011a6787ac5-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7be525c05b1942e1844d0073f7b9ab5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Heart Will Go On",
    artist: "Céline Dion",
    id: 129225916,
    release_date: "1998-01-20",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-30f0d78fdc23bfaddebbcb92b9edd43c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dc228f24c047970859347d7d3e60be6f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All You Need Is Love",
    artist: "The Beatles",
    id: 116348734,
    release_date: "1967-12-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2cbea4cbaf6cc54b8bd66dcaaa2b59c9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c105ffd0f6855c565cd3a0be47a1ee31/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Digital Love",
    artist: "Daft Punk",
    id: 3135555,
    release_date: "2001-06-11",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-01ef0c4982c94b86c7c0e6b2a70dde4b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e018122cb56986277102d2041a592c8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Firestarter",
    artist: "The Prodigy",
    id: 62126191,
    release_date: "1997-12-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f147f6b923a1bf1b973ab79e991a023-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/566d28d32080a6d82a2d4d145ea5ea7e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Purple Rain",
    artist: "Prince",
    id: 2794654,
    release_date: "1984-06-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d7c9849786a6614c20d1a1de49dc1bd3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88b5b2593429607587f5ea0f931af960/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "1999",
    artist: "Prince",
    id: 690101,
    release_date: "1982-12-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-35f1254f078a907de89df342c5de137a-22.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e4e1feb81f8ac2be66599b362d5451aa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Most Beautiful Girl In the World",
    artist: "Prince",
    id: 536618412,
    release_date: "1985-09-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ffb64ac3de69f3f7a045beea631e3cb1-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b94aca81917de9254d54cb45ba820677/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Gangnam Style",
    artist: "Psy",
    id: 60726278,
    release_date: "2012-09-06",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-57793edd9b2473c82d250249b623793f-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/45e4493fcc15997085491debddcf049e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waiting for a Star to Fall",
    artist: "Boy Meets Girl",
    id: 5404589,
    release_date: "1988-02-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a39d1c5b9db881e3858809ecf5507e86-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/65689ec5162126a39cf7bff42a28ba50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shake It Off",
    artist: "Taylor Swift",
    id: 89077555,
    release_date: "2014-10-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2380c0128a698e232331e8225944315-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/68b4e986958b17f05b062ffa8d7ae114/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "There Must Be an Angel (Playing with My Heart)",
    artist: "Eurythmics",
    id: 561785,
    release_date: "1985-11-07",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-56462aaac7ee142c495c6888bf6247df-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d68c40aa16097bb08b7537d1774db3fa/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'd Do Anything For Love (But I Won't Do That)",
    artist: "Meat Loaf",
    id: 3166759,
    release_date: "1986-11-27",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4966df866c5257d7eee46580c746a6e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b756a9a509d0c3e15d8919b57e6cb4ff/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "What's Up?",
    artist: "4 Non Blondes",
    id: 2184743,
    release_date: "1996-07-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d13de786b154b10be49cb7827af47ac-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22ab04ebf46e75a8c2c83a774c8831a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Will Always Love You",
    artist: "Whitney Houston",
    id: 2244121,
    release_date: "1992-04-11",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a42593ecbb6884418d6b1b2db3a4c8e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31ba5cdafbf9523b624afc282688429d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Barbie Girl",
    artist: "Aqua",
    id: 1115044,
    release_date: "1997-10-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce59c8a004ae528ba4745f453eb428b6-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/89c2dae2b498dc9ca9151324d02eca2d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nothing Else Matters",
    artist: "Metallica",
    id: 20481921,
    release_date: "1991-10-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0dc6a3ad317ca8a82610123e0fc5ff37-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37c5bd270ca19bcf6bd5e9354f9f3df0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Always",
    artist: "Bon Jovi",
    id: 538586982,
    release_date: "1994-08-10",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92059740f22ea74367426ccadead8c0c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f513327c13908b0def56f1fa0ca79789/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bitter Sweet Symphony",
    artist: "The Verve",
    id: 398570642,
    release_date: "1997-09-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a419590527595bbc5fea9a07f94d91b9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecc7727e897198892b98e7c019fba45f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Losing My Religion",
    artist: "R.E.M.",
    id: 136334560,
    release_date: "1996-11-18",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c529aa2f5a6fe7bd6579648bf885dfb8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a968ed3814acb2ef8f3929b1bebcb42d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "...Baby One More Time",
    artist: "Britney Spears",
    id: 540175,
    release_date: "1999-01-12",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cd9497093c088d198b226e058044787d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd904e47db5549124f099c1fef304dc9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "They Don't Care About Us",
    artist: "Michael Jackson",
    id: 8164642,
    release_date: "1997-11-13",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ca07ef6e13a98031151aa6e86af60724-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbed9a8cfcdd8a5e48cebbea8d8078ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Desert Rose",
    artist: "Sting",
    id: 2525873,
    release_date: "1992-02-10",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4edca5068bb6820aef6ce2a9918a9575-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/316afdaed93c4a18cf730389648d03d6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Believe",
    artist: "Cher",
    id: 786717,
    release_date: "2005-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e65c59b2245a6ebd33e25da0313d03cc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/af263778ad6ef2f3f5be381292ed02a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "More Than Words",
    artist: "Extreme",
    id: 1564562,
    release_date: "1991-08-20",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7c64505dcfc6444ef7ea744df97bb4e5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ddf2071955dc0bee2ee5cd5ced4d613/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Linger",
    artist: "The Cranberries",
    id: 1137657,
    release_date: "2002-06-13",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0687e4d1d0990601c81d3b01bb8e5ee3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dadf15bb8ea7e829bd69b2f3966c701/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Maria Maria",
    artist: "Santana",
    id: 82241406,
    release_date: "1999-02-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da7687b28c3f0f885f5a0f042cc1679c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/929132f50492c7acfab23b32722f4537/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wonderwall",
    artist: "Oasis",
    id: 84572613,
    release_date: "1995-09-26",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d41f35db23b700b359dc3225ca79d44e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d63b6ce2589b96cee3e6aa85d996023a/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "When You Believe",
    artist: "Mariah Carey",
    id: 596057,
    release_date: "2007-11-08",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bcb5f4ae5ce54009c86d6647b5ae95f2-91.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Enter Sandman",
    artist: "Metallica",
    id: 20481851,
    release_date: "1997-10-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ed306e418e79b81cf4a4707d3957dc7a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/37c5bd270ca19bcf6bd5e9354f9f3df0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Livin' la Vida Loca",
    artist: "Ricky Martin",
    id: 13111370,
    release_date: "1999-06-11",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-48f9cc3ac0725d5273fafff9f457c0ed-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/554607caa28b1531ea6f447aec02ecdd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Man Who Sold The World",
    artist: "Nirvana",
    id: 1105736,
    release_date: "1994-10-27",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-be2aad542197a67130e8ca5743a02005-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe1082c5ef54876802146897e76b592e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wannabe",
    artist: "Spice Girls",
    id: 3164155,
    release_date: "1997-11-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-424c75747f71ceb18fa48ceef8c2e197-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba4d6b08d01a59c34e14a8cd5727c6fb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Scrubs",
    artist: "TLC",
    id: 1075781,
    release_date: "1999-02-22",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-300c6c7963f5373f4628e04bb2fab671-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6dd5f40e7688ba155a5ef557977e95d3/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Don't Want To Miss A Thing",
    artist: "Aerosmith",
    id: 624823,
    release_date: "1998-06-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6effc395335f900ecbc7bbf2869a4e8a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82bb160fe7b46cca64dc989a4f5843d0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All Star",
    artist: "Smash Mouth",
    id: 917717,
    release_date: "1999-08-02",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fb0a9f7043f70c7a89aced8c89dc89c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34a4542cb4e8a54639674ed6aab1bd65/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Coco Jamboo",
    artist: "Mr. President",
    id: 2194974,
    release_date: "2008-09-16",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ee8fa053a712988896018aada47bdcad-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c3152ba548375ae7fba3399a0e6b38fe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Bad Touch",
    artist: "Bloodhound Gang",
    id: 953105,
    release_date: "1999-09-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d75c544c4741a34f90dfa42b87554dba-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ecff532dd84c6538099b58baefccb8fb/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Scar Tissue",
    artist: "Red Hot Chili Peppers",
    id: 725254,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eb594dafcc4378286fcc5eabcd9ff6c9-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bed Of Roses",
    artist: "Bon Jovi",
    id: 74628110,
    release_date: "1992-04-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e6a132dbd5dd671cc37e86ecdecd93c-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a2821c5cfa61955ddd982c15cc21882/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Earth Song",
    artist: "Michael Jackson",
    id: 8164645,
    release_date: "1983-11-13",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a4beb688d1fc48c1ecb063ca08b03152-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbed9a8cfcdd8a5e48cebbea8d8078ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come As You Are",
    artist: "Nirvana",
    id: 121629010,
    release_date: "1996-03-25",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a79e3527aa963b6e1a603ad89275e23e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5aa7d9349db91bb5a883ccc4a235d4c6/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Thank You",
    artist: "Dido",
    id: 962173,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a1bc29167e93809ebb3cf9026739ea4a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3eaae15d23993db3cd9c69ae113c4333/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Cry",
    artist: "Guns N' Roses",
    id: 1169677,
    release_date: "1991-09-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d494c13c6da1a5f00ba3eb26106a19bf-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/546fe1eca5d3bf48c6a48fb3c0ddac63/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Smooth",
    artist: "Santana",
    id: 82241402,
    release_date: "1991-02-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-94aaceeb29fcd6b86c4cdc2135df6d2c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/929132f50492c7acfab23b32722f4537/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Black or White",
    artist: "Michael Jackson",
    id: 15593566,
    release_date: "1981-10-16",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d99cc18414c8d4dd96eace504aeec035-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Kids Aren't Alright",
    artist: "The Offspring",
    id: 137233986,
    release_date: "1998-06-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c22d48f29c8acdb9dd2a3476237bbb2-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7d8a61b8c4b118e642acfb3db0a45a1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Crazy",
    artist: "Aerosmith",
    id: 916339,
    release_date: "1993-03-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e552204666353de052ebd825efdbc265-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d57056281f6ba958d1056b103e7357aa/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I'll Be Missing You",
    artist: "Diddy",
    id: 2794603,
    release_date: "2004-03-09",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-77384daa602dc69238c8120aee4eaa7c-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/755dc7da07ba60a8c879963858150f84/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "You Are Not Alone",
    artist: "Michael Jackson",
    id: 8164649,
    release_date: "1997-11-13",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8beb0e99af20a7afcadfc4b914b5dfa5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbed9a8cfcdd8a5e48cebbea8d8078ea/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Boy Is Mine",
    artist: "Brandy",
    id: 662355,
    release_date: "1997-01-01",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd8a0e92c85368491ccb10c463eee254-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4112854d5ad8f7b5dffa421c9df06515/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Freestyler",
    artist: "Bomfunk MC's",
    id: 5363024,
    release_date: "2000-02-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b3c72bdc22f4d99801d990e8646b2ecb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/693d6472f1c44dd5a55432acef70d193/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cotton Eye Joe",
    artist: "Rednex",
    id: 88328649,
    release_date: "1994-10-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-186991c30b6bfa5227144ca75c1ff936-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6c9c7419d8c50f843b5e42e8300ebdcb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Scatman (ski-ba-bop-ba-dop-bop)",
    artist: "Scatman John",
    id: 99360002,
    release_date: "1995-06-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2b889634bb2a3da74d6b07a7b184851-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42f2703765ad6be59c106e0d8db10570/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All The Small Things",
    artist: "blink-182",
    id: 127354207,
    release_date: "1996-06-24",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-903d5c0308fd67afc6dde947d8a60981-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/50627ad1a5ce0215359f0e8b2e37a01f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Basket Case",
    artist: "Green Day",
    id: 678044,
    release_date: "1994-01-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8518dd3ae3f2c754a4e285546e28f697-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e17054b7bfa6576f64ea867b71ef479/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Torn",
    artist: "Natalie Imbruglia",
    id: 554446,
    release_date: "1997-09-05",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c48405f575393141b2c3edeab06b2e3f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce07121876070fc4ac134e9111871e5f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Amazing",
    artist: "Aerosmith",
    id: 916343,
    release_date: "1987-03-29",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f50d8089f37a8bc1a41c045f84f0e9e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d57056281f6ba958d1056b103e7357aa/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hero",
    artist: "Mariah Carey",
    id: 596015,
    release_date: "1997-11-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a3f83b026c0792c3113836a7e4b899d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Without You",
    artist: "Mariah Carey",
    id: 489646332,
    release_date: "1993-04-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98a6f188d0538d069cfbba3ba31c0fc1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/97b4d44589c542d417003de8c5d68d2f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Return of the Mack",
    artist: "Mark Morrison",
    id: 703748,
    release_date: "1996-04-22",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da7e01ceaada1c767829f08e735b0bb1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/655e659d77faed4a3dca4f9c5a0fcd9e/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ironic",
    artist: "Alanis Morissette",
    id: 789250,
    release_date: "1997-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6753ff67d34ea9be279b48256daf74e8-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/191593fa5b96cd100998796195095c8f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Heal the World",
    artist: "Michael Jackson",
    id: 15593565,
    release_date: "2001-10-16",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-33e7b8736ba7e87030703ad2af11430a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dreams",
    artist: "The Cranberries",
    id: 1176439,
    release_date: "1997-08-23",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8f1d0857e560bdc9a5604325d87ffbb-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/29daf9a4798a4a4de0275446b77683c3/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All I Wanna Do Is Make Love To You",
    artist: "Heart",
    id: 3482138,
    release_date: "1990-03-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a86f88d42ea837c4a01f5d03d0a2f67-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9e45eca89fbb8d759a7e4a5d66349a93/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "One Headlight",
    artist: "The Wallflowers",
    id: 2193499,
    release_date: "1997-02-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0b34ff58dc306b4e51606aaf001e07c7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d3fcc02ae974d3eb6ff036ba325fb1e8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Send Me An Angel",
    artist: "Scorpions",
    id: 927907,
    release_date: "1991-01-07",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e154046e457298847e4c21d3b948f3d7-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d1724dd3ac61f2e1df593c543ce2b22/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Truly Madly Deeply",
    artist: "Savage Garden",
    id: 117262952,
    release_date: "1996-03-23",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3e2152deb69ad631fafccf70ae4a28ac-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d393bffce44a4cf6ac86fab7adc51c62/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Save Tonight",
    artist: "Eagle-Eye Cherry",
    id: 2252935,
    release_date: "1998-05-04",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fb9c3cb87eb92092b981af7196b59a8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e266242f0f87f9f394f12b1ad3b549f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If You Had My Love",
    artist: "Jennifer Lopez",
    id: 15475926,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67982c16d806e6654446aa62ed9737c7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9e59d8b00661ff2971cf7e62e1d780c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Insane in the Brain",
    artist: "Cypress Hill",
    id: 1015862,
    release_date: "1993-07-20",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-86fefd9738a2a6e337736f0261570acc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff3a4fbb250a131f8cf3f413814eb29a/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Black Hole Sun",
    artist: "Soundgarden",
    id: 78631539,
    release_date: "1994-06-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-317614ee96aeb76afc0a2c51a20cf6bc-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/94248b664be0890be31cabf62a068293/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Boom, Boom, Boom, Boom!!",
    artist: "Vengaboys",
    id: 397344472,
    release_date: "1999-12-10",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba04da3f9e7b6225c4af615e4aaae592-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b9f7f702c76d22e23cabc3fa706a100/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Men In Black",
    artist: "Will Smith",
    id: 626417,
    release_date: "1997-11-25",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-24676370a0b252c614085ed5b0646411-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e06e37cd6c0b10dde07ff71b4a95fc2a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Why Don't You Get A Job",
    artist: "The Offspring",
    id: 137234014,
    release_date: "1996-12-02",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a6f425a1ab9ad11022c6f4444496771-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7d8a61b8c4b118e642acfb3db0a45a1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Sweat (A La La La La Long)",
    artist: "Inner Circle",
    id: 7777963,
    release_date: "1992-12-14",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cbed12df9156867b2cbe2ddfbd92629-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1c95e242a8ba84335affb3111ef14410/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "When You Say Nothing At All",
    artist: "Ronan Keating",
    id: 3978398,
    release_date: "1997-11-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5214b5eb6dc7fba7d869794f74c21917-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a7ebcd872ef1170457992f28f878d08/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "More And More",
    artist: "Captain Hollywood Project",
    id: 143154464,
    release_date: "1992-07-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5bedd004066286ab1c24d00a47aade1c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55053ac3f523c231fb7850e67731b9e6/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Everlong",
    artist: "Foo Fighters",
    id: 4762041,
    release_date: "1999-10-30",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ac995d4ffd81eccda77f35db0fc2fb4-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/266f01f1c7a04843d11cd08f9c07d11f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Candy Rain",
    artist: "Soul For Real",
    id: 1110898,
    release_date: "1997-09-10",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d8e91e8770f15c0c702766bfc0c9978b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6583e3244e2bec938a38f0174018740/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Remember the Time",
    artist: "Michael Jackson",
    id: 15593563,
    release_date: "1981-10-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95771b49f6ebe27f6759e39f49083cfb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bailamos",
    artist: "Enrique Iglesias",
    id: 2604907,
    release_date: "1998-11-27",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67850a0fb50ebea85df3f6b5ca9c9a6b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5909ac32e17d6f7c12cc1443aa88428/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lithium",
    artist: "Nirvana",
    id: 13791934,
    release_date: "1991-09-26",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-457c2231637b46c194c8894b4dbae31f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0282817b697279e56df13909962a54a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Dragula",
    artist: "Rob Zombie",
    id: 2307303,
    release_date: "1998-08-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e7b7148a74f5bf3ed95d0a10f79231f-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9ec5b52540fbb85df121d796616a8bfc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everybody Hurts",
    artist: "R.E.M.",
    id: 121921372,
    release_date: "1992-04-01",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-304c4eddd6bb91ad7ed117ef74680fdb-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8f9e1252bb3ee900f342a2c83a378922/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Always Be My Baby",
    artist: "Mariah Carey",
    id: 596030,
    release_date: "1997-11-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3cd8776695cf2c5dbef9450d6f1661bd-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a1aeef41619907cc283d4eb1eac2fe6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "When You Say Nothing At All",
    artist: "Ronan Keating",
    id: 3978398,
    release_date: "1997-11-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5214b5eb6dc7fba7d869794f74c21917-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a7ebcd872ef1170457992f28f878d08/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nobody",
    artist: "Keith Sweat",
    id: 3616541,
    release_date: "1994-10-26",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0467575217253a0aee7768b0f956da4f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a33be0dde86b9bc7579703c246f9c33/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Down In A Hole",
    artist: "Alice In Chains",
    id: 7675186,
    release_date: "1996-07-29",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5846f4d41897e94b17f6480f122042a4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1dd96fe2b1ab3ed2b750fad203de61ee/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Mr. Jones",
    artist: "Counting Crows",
    id: 1138006,
    release_date: "2003-11-24",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7b249f85a9d7001ed0bf5217c6309d2d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/819a381af9372f741787144848278399/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Insomnia",
    artist: "Faithless",
    id: 7035328,
    release_date: "1995-09-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8a7302b9a090873bb60955a7ef8e9198-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa2aaac3c9182ce203abc4319107d143/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Song 2",
    artist: "Blur",
    id: 44133771,
    release_date: "1997-02-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c633c54de1797f0289aa98e284eb42cb-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c171487c2b0cd14f12b015aa76304394/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pretty Fly (For A White Guy)",
    artist: "The Offspring",
    id: 137233982,
    release_date: "1996-12-02",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f51479a3de8d32a7c22c82bbc4d56a9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f7d8a61b8c4b118e642acfb3db0a45a1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "California Love",
    artist: "2Pac",
    id: 910683,
    release_date: "1999-12-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-03b6afdebdf4f72966aa3a5934d78e9a-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49856d92a31e284c7fe34b7971dba0b0/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "I Knew I Loved You",
    artist: "Savage Garden",
    id: 532605,
    release_date: "1997-02-02",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-ba4b1767f6961a78d77f31bce9600137-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b96611d8619d9badef37b832da16c7f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Lovefool",
    artist: "The Cardigans",
    id: 910474,
    release_date: "1998-01-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-813c750b6f5cc2077fb5846c4c48c21d-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c7dcdea2a0d4f52f2a9fae5610194653/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Nookie",
    artist: "Limp Bizkit",
    id: 94613610,
    release_date: "1998-07-29",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-edd162580052a50c9ef667a03e9bebf2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/997093089e97966606459e719848329f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Fantasy",
    artist: "Mariah Carey",
    id: 1013792,
    release_date: "1995-10-03",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3f697bffdb916d73e13715e3b2697ddf-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/98ab83037b73f9fab9d97b5644ea2668/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Get A Way",
    artist: "Maxx",
    id: 16204632,
    release_date: "1993-03-23",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a342b780b18f24b9705bcbe3f2c5ec9e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0eba2c7261d4dd4a09d1b9629e339b7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Freedom! '90",
    artist: "George Michael",
    id: 414745982,
    release_date: "1990-10-20",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc1721ab9e911ebeeb0eb7dcd9ff7ab2-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/82ebe4b22d1f14bd0b8a849c952f3689/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "This Is How We Do It",
    artist: "Montell Jordan",
    id: 107351278,
    release_date: "1995-09-25",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7dc5cdd10b4533c6e748a999d938e28e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2490ebf535cce9277eb16332e1eb9a6c/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ordinary World",
    artist: "Duran Duran",
    id: 3130430,
    release_date: "1988-11-09",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13f95a8c35fff35b9b81907d47313ffa-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ed10ab812e802c87e423db20800f678/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Even Flow",
    artist: "Pearl Jam",
    id: 7675129,
    release_date: "1992-01-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0d30c70c2eb5a953e360927dd4ff06f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00ee8c40862fb4e258fcbeaa17d7199e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Return To Innocence",
    artist: "Enigma",
    id: 3132752,
    release_date: "1994-01-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a27436e9268d14e949630f7c283c8ab-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00eac2b394a1e76d5b42824ac11a57c0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gonna Make You Sweat",
    artist: "C+C Music Factory",
    id: 829895,
    release_date: "1999-10-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2c11c2b282ceeb4264489682d90be69e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0443774b0fee838507fc9221e9c8b6b9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Runaway Train",
    artist: "Soul Asylum",
    id: 1057312,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cb734d80dbbee0c473165d313789712-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ad93113b7bbb0061171aa3db8a680696/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "We like to Party! (The Vengabus)",
    artist: "Vengaboys",
    id: 397344502,
    release_date: "1999-12-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4de7be9cf17655e7161738e7e4759cc-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce258c669c4eeed127859e9386f7b76a/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Stairway to Heaven",
    artist: "Led Zeppelin",
    id: 88003859,
    release_date: "1969-01-12",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00bd440c9ec8b85f26d638febfda5e7c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/460a0edd96f743be03b7405eac38c633/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Stayin' Alive",
    artist: "Bee Gees",
    id: 406815322,
    release_date: "1983-06-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c2a7afa0dc7677076cd214adc549edbc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/062e4071ebfc701e5f1ca935e69a0b5b/250x250-000000-80-0-0.jpg",
    genre: "Disco",
  },
  {
    title: "Born to Be Alive",
    artist: "Patrick Hernandez",
    id: 4077573,
    release_date: "1979-03-20",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-37b418d9f4da9fddc61da0c4d3e6bba7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cd1f9c428f3d6d84c4771f7422186afe/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Don't Stop 'Til You Get Enough",
    artist: "Michael Jackson",
    id: 15586296,
    release_date: "1983-07-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e3f1ec8b9c629b417dd2938fa5fa2df7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/baf09a24b48b82e3af270efba101d07b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dance Hall Days",
    artist: "Wang Chung",
    id: 907794,
    release_date: "1983-11-19",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fee8590e5e47fa862e37c34488da626-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f75a7f075154438cb8aa62a4f0c1963b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Urgent",
    artist: "Foreigner",
    id: 2814888,
    release_date: "1987-01-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3694c4fb4f68f6cd6cc7acf4f6c277c-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a7545dbac40fbecabeafc4847bcba549/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Turn Me Loose",
    artist: "Loverboy",
    id: 1025465,
    release_date: "2003-03-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b7b5cb1d97613d45a9b5ff327c58dbbe-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6883eab08b168388d73c2783aae41469/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Logical Song",
    artist: "Supertramp",
    id: 7087537,
    release_date: "1979-10-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-07aacbb80b5eac999e3c42a50f7adea4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fee526711039ce10381a3091204faca4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Breakfast In America",
    artist: "Supertramp",
    id: 7087529,
    release_date: "1979-10-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a1e10490260a97a7b160dc636c5a65a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9c82f1673a2526a69bda92271d24c087/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Rosanna",
    artist: "Toto",
    id: 1079628,
    release_date: "1982-04-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa77c5ba20eeaea5277011bf84e1209c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/153332e88a14255a8c3d5959a5a21882/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Africa",
    artist: "Toto",
    id: 1079668,
    release_date: "1982-04-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4b888aec204edec33c4619ad4a5410b9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/153332e88a14255a8c3d5959a5a21882/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tragedy",
    artist: "Bee Gees",
    id: 350027801,
    release_date: "1976-05-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0ac78beeda511953fab43c1a32462c87-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ff58c365559fb6061db49dbdcfdf2287/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Walking On Sunshine",
    artist: "Katrina & The Waves",
    id: 3166724,
    release_date: "1983-04-20",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6b8f35812cdd6a881fedd8d3aeb23ff1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1a81bcd5543be9705c0c66e57593a258/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweet Dreams (Are Made of This)",
    artist: "Eurythmics",
    id: 561836,
    release_date: "1983-10-21",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a29ac8f41e96136fe43d146a25cf74d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/364f0d4ea4b5452b59e6664b9e5480f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Final Countdown",
    artist: "Europe",
    id: 619310,
    release_date: "1984-03-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ecbb97adf10b2aae1d16b5cc502c9666-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/da44873bbb18e25f946863209fcbbbe0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "YMCA",
    artist: "Village People",
    id: 78595927,
    release_date: "1978-05-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f07086ceffc98b286ab441997ff0004a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c446db15539f4e82846c606b072fe8b7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "It's My Life",
    artist: "Bon Jovi",
    id: 89870993,
    release_date: "2000-06-13",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b1540a59ab0555d999605a442213fd2c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/08a906bd35e5707ef08ceed3e36db1c7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Jump",
    artist: "Van Halen",
    id: 97098410,
    release_date: "1984-09-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-367531769e408fa1fff3c1eece190e73-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0649448bcdeeeb48c25edc0e83653711/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "You Win Again",
    artist: "Bee Gees",
    id: 348129241,
    release_date: "1987-09-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cfeb951dad11cb4763ddaa6092827cb4-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e9ae81018b27c2eb06a0fb9b10427b7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say Say Say",
    artist: "Paul McCartney",
    id: 107768560,
    release_date: "1983-10-02",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-62f222d8e81028167ae0d9f170ecd0b0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28f8b1fe717413c1b709da5f7234b723/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hope Of Deliverance",
    artist: "Paul McCartney",
    id: 10176186,
    release_date: "1993-03-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f2b391c166515d926a0f73eb6039be7a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87c3d6b2e71fa02fbd26ffff59f84035/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Night In Bangkok",
    artist: "Murray Head",
    id: 89977351,
    release_date: "2014-11-24",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-acf24aea0630d453861601ad0f58be01-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/84d654a1c8cd9281bfa2f1f118e361c4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Say It Right",
    artist: "Nelly Furtado",
    id: 7562986,
    release_date: "2010-11-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b34f0a644d84bcee63aaaf1c2a7cdab4-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fbd6d06c71d72755bba27af0b1e3c88e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Maneater",
    artist: "Nelly Furtado",
    id: 1151141,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0db445dc81c8ae03116fbfc7b01ca1ff-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d3625d9f19527440769e7f8cc09db85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hung Up",
    artist: "Madonna",
    id: 679217,
    release_date: "2005-11-14",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc72b1c3b569b903db2c75969db478d1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8e499d7b0e9be5b77eb6c2697e179fe3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Give It To Me",
    artist: "Timbaland",
    id: 4685512,
    release_date: "2007-04-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8bbdc0367f8ee8e3cc6a6f12d89ed60c-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/016ad07cfa4af4432d766abc25c9ee8c/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "All The Things She Said",
    artist: "t.A.T.u.",
    id: 62070435,
    release_date: "2012-11-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8b748666a964115e726456988f0ae66-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ca53a09389eda98a6894cf603a98a9c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Dance",
    artist: "Lady Gaga",
    id: 2603555,
    release_date: "2008-11-24",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da975afec91e06a8dafcc3a95512d1d9-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc24d60a998e1a296f0c22efa8ddffd2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Valerie (feat. Amy Winehouse)",
    artist: "Mark Ronson",
    id: 7923756,
    release_date: "2007-04-16",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74fb75139d912676b474e32994c22d28-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aa17bc2f15022dd727fd21922e90c9c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Billie Jean",
    artist: "Michael Jackson",
    id: 4603408,
    release_date: "1989-10-26",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-136c90241aa34cbe924159289df20d65-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a0ad67d1beb761f2cb9f8b60e5bcf07a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beat It",
    artist: "Michael Jackson",
    id: 555640,
    release_date: "1983-11-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-edd7c2135ad76391a35c8dc2dcf45483-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/92a024220a9532489c75c9d994835697/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Thriller",
    artist: "Michael Jackson",
    id: 14874867,
    release_date: "1985-07-18",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-49f164459fb60684decc0c06d40c413c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/041e3b8fc5ed298264b6e374b825b24f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bohemian Rhapsody",
    artist: "Queen",
    id: 7857234,
    release_date: "1975-01-01",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb2d30832c1871ac35261c603e6841fa-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9d5f6e56b755d104525cf3a0810356bc/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Smoke on the Water",
    artist: "Deep Purple",
    id: 3152610,
    release_date: "1972-07-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25376c5ff0367baa43aec3775818e2b9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c4cbc4f2ad6cbd36d1d7549178e301d7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "My Sharona",
    artist: "The Knack",
    id: 6812046,
    release_date: "1979-08-20",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-424fe4d99e3f61af2893a922c70158d0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87655ffd4c1910baa30c5459cbfaadb9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Das Model",
    artist: "Kraftwerk",
    id: 1006803652,
    release_date: "1987-05-26",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-502ac78ef780eecf899de083a0f7d9a7-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4270c9ccd41bd480c76610ead7128d66/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Dancing Queen",
    artist: "ABBA",
    id: 884025,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8708a478849e903d5065f4c9caaf8641-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Highway to Hell",
    artist: "AC/DC",
    id: 92719900,
    release_date: "1979-07-27",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c9dcc5dffa3210c0a7dd4d7c37f84540-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/de48d988a82fc401f4b9bafc2639f7d0/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Heart Of Glass",
    artist: "Blondie",
    id: 3151379,
    release_date: "1978-05-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-08f24ec42eab3b8f97f34648d02b87c0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93eb8b69b5b5d03af224af1c9d28bc53/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "I Feel Love",
    artist: "Donna Summer",
    id: 487042352,
    release_date: "1978-04-20",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f46fe3dd66dfe25b58bdba8d00a1e97a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/31ae32eedb76d458f95b26a9ba35ac49/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Shook Me All Night Long",
    artist: "AC/DC",
    id: 92720048,
    release_date: "1980-07-25",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-884c175339dd0ada8aaf2c6e6e5b9216-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/41041b14873956eff0459c8ea2c296a8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Livin' On A Prayer",
    artist: "Bon Jovi",
    id: 538660022,
    release_date: "1998-08-08",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-56d2788c18b4000cb3174b3d2134fcd7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0365311a9d03c267f175e0ef79f40c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Pour Some Sugar On Me",
    artist: "Def Leppard",
    id: 447552822,
    release_date: "1987-01-19",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-43ce01c5cb659fec6f5b4c0b2c626bdd-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86d5af2077f5b6ef18fd0ca158a91134/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Celebration",
    artist: "Kool & The Gang",
    id: 906568,
    release_date: "1977-09-10",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80d4346440c31f99e0a836790a355bf4-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e993fe7d2c6e0a4fe259485d940aa7a5/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Girls Just Want to Have Fun",
    artist: "Cyndi Lauper",
    id: 76057844,
    release_date: "1984-03-28",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0e3a8101db616bf26e58fae135119eea-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a00e104be064ecd2ba490ded73bd6698/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Way You Make Me Feel",
    artist: "Michael Jackson",
    id: 59509541,
    release_date: "1982-09-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-31c72e2585b6238c16c115664707b680-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "(I've Had) The Time Of My Life",
    artist: "Bill Medley",
    id: 13128250,
    release_date: "1987-12-18",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-234cd1a120137e3e8f683c2cf532825d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f24ac40f8c7a0dbe438399788763a042/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy Little Thing Called Love",
    artist: "Queen",
    id: 568121002,
    release_date: "1988-10-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce852e942a9d52502ddc0f2922bdc97c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8b8fc5d117f9357b79f0a0a410a170e8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Take Me Home Tonight",
    artist: "Eddie Money",
    id: 1081064,
    release_date: "1986-10-15",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54672fa6745545a1a2b120cf72e4f84a-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bdfbfeffad8d5c31d1084075b5487cc2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Red Red Wine",
    artist: "UB40",
    id: 3129568,
    release_date: "1983-03-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a6e0685e488b7aa18cb0904416632a3-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba1ee8b4130e3c0e2606dfe365312dab/250x250-000000-80-0-0.jpg",
    genre: "Reggae",
  },
  {
    title: "I Love Rock 'N Roll",
    artist: "Joan Jett and the Blackhearts",
    id: 545992732,
    release_date: "1981-11-18",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1aff44bfddee18618ef3d44d2c19bb3c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8c4fb793cfedbb94d6e8926f3647eb8b/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Another One Bites The Dust",
    artist: "Queen",
    id: 67503793,
    release_date: "1981-01-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d3709b45bfb7f38189f351c0ad237022-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1e1883d47e9e07a3b6dbf5c9249452d8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Smooth Criminal",
    artist: "Michael Jackson",
    id: 59509621,
    release_date: "1982-09-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c74526ed21e51f83944b58b0aaf7bcdc-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rock with You",
    artist: "Michael Jackson",
    id: 15586286,
    release_date: "1983-07-04",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b39eebac74144acd8ac092f6a4a7805b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/baf09a24b48b82e3af270efba101d07b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Safety Dance",
    artist: "Men Without Hats",
    id: 63440828,
    release_date: "1982-02-11",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bad51a5c70b9f8cc3b591e1c1a2844ed-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4ff46d3112e44464f09f67d161012f23/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All Night Long",
    artist: "Lionel Richie",
    id: 920062,
    release_date: "1983-06-22",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-85de93bf33918602b5587fbc5adc3e7f-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7050e918a1ead7bdbd7cadd9dfa2b858/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Eye of the Tiger",
    artist: "Survivor",
    id: 651355112,
    release_date: "1982-03-29",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f35bde1bfa6feea24fa0cff2d93879d9-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f338910e3f6270697e56b4a20c57712e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wanna Be Startin' Somethin'",
    artist: "Michael Jackson",
    id: 831216,
    release_date: "1983-07-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b14191de47b59c80f6df4b710c3e1a1b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/544862aa5be45bc82ad4ab1a14daf63a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wake Me Up Before You Go-Go",
    artist: "Wham!",
    id: 15194531,
    release_date: "1985-01-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5a8a41d81b20bbf652bf4251937b622d-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c1858a4aa5d732a60c8cfcf424f24ef/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's Raining Men",
    artist: "The Weather Girls",
    id: 555755,
    release_date: "1982-11-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b784a20409fe9d1c6bf6e7f312b71788-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9fe1f7455be7fa598bf2777ad15f08d8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Back In Black",
    artist: "AC/DC",
    id: 92720046,
    release_date: "1980-07-25",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cfc0e5baab3f7ce7758e259482bd8681-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/41041b14873956eff0459c8ea2c296a8/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Man in the Mirror",
    artist: "Michael Jackson",
    id: 59509591,
    release_date: "1988-09-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c1c2d12e99b1113a88d0e20de2d8d12b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Pump Up The Jam",
    artist: "Technotronic",
    id: 2961498,
    release_date: "1999-03-23",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2644d171e6b7832e3555cf8f5d50ff1-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8ddb94a0ba17254188a54d18b6173f7f/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Uptown Girl",
    artist: "Billy Joel",
    id: 1025659,
    release_date: "1983-08-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d8db355da7b4f057c2e3260b4b6eab9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fa5a6fc95f4adfb8784dcd719422c26e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Conga!",
    artist: "Miami Sound Machine",
    id: 565055,
    release_date: "1996-10-03",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b104ddcb9c5d99419756cda891b8c3ff-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1b0411abe7d773fd5e5d56c47da318c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Glory Days",
    artist: "Bruce Springsteen",
    id: 15586215,
    release_date: "1984-06-04",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9ddb5203765be707ab60c4ad77be75bc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106f651bf559796879b90e83c50b3e96/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Walk Like an Egyptian",
    artist: "The Bangles",
    id: 610231,
    release_date: "1983-04-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-28c8f2fd7ec94b06c2db78ab23ffba6a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/938d17e5a07d6178bcabbe2349a79ba0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're the Inspiration",
    artist: "Chicago",
    id: 3616316,
    release_date: "1984-11-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72fb7217793f1fe5890e7ad66ffde93b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9fb83435f0d9bfee9397100320c59cfc/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tainted Love",
    artist: "Soft Cell",
    id: 1173622,
    release_date: "1982-06-15",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d80f9217e72b4cf8126b5513e6daea2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a809e8767a0cc557be1c894c5ed1c71e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm Is A Dancer",
    artist: "Snap!",
    id: 434215172,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-74434845f1300cad0afb9f36e69d5199-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9836fcf60102662b686f93d01bcda69/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Ice Ice Baby",
    artist: "Vanilla Ice",
    id: 2397026,
    release_date: "1992-11-04",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6961b10e2b35c40432f0dc85e3e3ce3e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/177bec5553a37b3ecf7d548ca8f6d370/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "U Can't Touch This",
    artist: "MC Hammer",
    id: 3090793,
    release_date: "1990-02-12",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-60ed7acfb29dece4c21202221b46995c-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3558d89040fc95f8978c67b09aa2d16b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Like To Move It",
    artist: "Reel 2 Real",
    id: 524374752,
    release_date: "1994-10-10",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4824ad8b5e69b61430221fa1a445067b-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/58ba0c77278cb7f63bb9fbd094e2fabd/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Everybody Dance Now",
    artist: "DJ Twilight",
    id: 8151890,
    release_date: "2011-01-10",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6350f0d56ae43e8baa9bdecb226adb6f-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1c2e1e1f519c64b2059d352b9fc8f432/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Remember the Time",
    artist: "Michael Jackson",
    id: 15593563,
    release_date: "1991-10-16",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95771b49f6ebe27f6759e39f49083cfb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/93a5354699d552666448e1c87c976605/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dirty Diana",
    artist: "Michael Jackson",
    id: 14874854,
    release_date: "1985-07-18",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-65d6ab9c90502b1c8c163794cd2dc81d-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/041e3b8fc5ed298264b6e374b825b24f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Another Part of Me",
    artist: "Michael Jackson",
    id: 59509471,
    release_date: "1988-09-14",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ffb9bf3cb4b740fc1be4e86c04dea015-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/995b33eaad9a62409c822ce9c670e542/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Mambo No. 5",
    artist: "Lou Bega",
    id: 994493,
    release_date: "1998-05-24",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3705a6ed322a15e6b4afd58312d3248b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a827921da4e6afde64294d7483cbf0e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Love Is All Around",
    artist: "Wet Wet Wet",
    id: 910912,
    release_date: "2007-03-07",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2f8c743311dd0045ae2ef59eed43f5ad-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/447dbf905a9bc8113add6d914babe853/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Lady In Red",
    artist: "Chris De Burgh",
    id: 522699602,
    release_date: "1986-06-15",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-708eb1b0c787e0f3f4dd439ad3d1f23c-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5653d56284b179befadab5070bfc6cf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Un-Break My Heart",
    artist: "Toni Braxton",
    id: 632936,
    release_date: "1993-10-27",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7bee343098f7c92081516bc1e72a3367-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/879e6b0a21327acd4160cf89322aec52/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Heart Will Go On",
    artist: "Céline Dion",
    id: 14552280,
    release_date: "1998-11-07",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8049818b9083d987f933828927580650-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/177346106112348e15ec4f9add0c1ef4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wannabe",
    artist: "Spice Girls",
    id: 3164155,
    release_date: "1997-11-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-424c75747f71ceb18fa48ceef8c2e197-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba4d6b08d01a59c34e14a8cd5727c6fb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Everybody (Backstreet's Back)",
    artist: "Backstreet Boys",
    id: 15608663,
    release_date: "1997-10-09",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a9594c8d09b5c734e97a0e7d3bc6d9d7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4f5bf0b5232ddc0f8453c3014c83a592/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Blue (Da Ba Dee)",
    artist: "Eiffel 65",
    id: 88825391,
    release_date: "1998-01-29",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-528a0215cc05c073936d62ced45de231-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/457d79704890c7d827bdf92dbc037087/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Scatman (ski-ba-bop-ba-dop-bop)",
    artist: "Scatman John",
    id: 99360002,
    release_date: "1995-06-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e2b889634bb2a3da74d6b07a7b184851-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/42f2703765ad6be59c106e0d8db10570/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Maria Maria",
    artist: "Santana",
    id: 82241406,
    release_date: "2001-02-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-da7687b28c3f0f885f5a0f042cc1679c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/929132f50492c7acfab23b32722f4537/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Sweet Escape",
    artist: "Gwen Stefani",
    id: 886428,
    release_date: "1998-05-12",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7ec43fcc09ac46232844dc3e340d184d-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c10f36c2ce7bf1006ced08147d02de32/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Mr. Vain",
    artist: "Culture Beat",
    id: 29794781,
    release_date: "1993-05-11",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-11e52a1e9077e17c19396bf631e64949-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a652433c79217dc6e885cfa28428f174/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Send Me An Angel",
    artist: "Real Life",
    id: 75766534,
    release_date: "1983-01-01",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa1621536dc9256b717deac544153f24-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b97450201d81dc3735b8e9dc34e8c268/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Easy Lover",
    artist: "Philip Bailey",
    id: 82199946,
    release_date: "1987-11-06",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8464ae439cdd60baade7a6a1935a0bee-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8afa0ca97a60b87740bb36ad17c8591/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Lose My Number",
    artist: "Phil Collins",
    id: 134036238,
    release_date: "1985-10-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-58ee2bd6f17c5eda1cf8ccae549d8373-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Invisible Touch",
    artist: "Genesis",
    id: 3145739,
    release_date: "1987-10-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8be66c79538022dfaf04f6936f463ac0-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0fa14b7e1c7c350f389fc7fb1e53cd28/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "You're the Voice",
    artist: "John Farnham",
    id: 2221501,
    release_date: "1988-09-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d714890d76498e83d2346a56583b9f62-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/28ebbfbe9136e36c92560ef9243b16c9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Juke Box Hero",
    artist: "Foreigner",
    id: 2814884,
    release_date: "1987-02-05",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9711f22462fb784285e6b59c131e2eb9-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a7545dbac40fbecabeafc4847bcba549/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Karma Chameleon",
    artist: "Culture Club",
    id: 3090499,
    release_date: "1983-03-01",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-20cab00420def6df1ab52232281d6c15-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3012d9bd9d57d1764e2549b2301777d7/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "When the Rain Begins to Fall",
    artist: "Jermaine Jackson",
    id: 4311056,
    release_date: "1989-10-28",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-478198df9d8cdc6b1e9c46741bdaca98-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9bdf4f76c2b48ed528956a271f44818/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Upside Down",
    artist: "Diana Ross",
    id: 906582,
    release_date: "1987-09-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fda9684d7c47c6e9ca03806123b681de-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dae9c863ba27686af441666135693de2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What A Feeling",
    artist: "Irene Cara",
    id: 1434081,
    release_date: "1983-02-21",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0a4cdaad2540a7da575f3a6e812cd12-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/204672f30d473f7969e36f687ff6ee02/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Major Tom",
    artist: "Peter Schilling",
    id: 3605281,
    release_date: "1983-06-27",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-26c6831e728544ffa14ed5406d6265ef-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d9e877767b4c31be9a7ac73979e8e2a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Der Kommissar",
    artist: "Falco",
    id: 142334565,
    release_date: "1982-02-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f435c39a820255d32683f19a5a9f708-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8bfb068c2769c3e87bb90c0fa5a37d1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Loco-Motion",
    artist: "Kylie Minogue",
    id: 137232280,
    release_date: "1985-01-21",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-576b147cc8810622c5ffc4093dd608f3-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0b2676ab5b25fab30c4e9a6d4294c839/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Gotta Feeling",
    artist: "The Black Eyed Peas",
    id: 4619466,
    release_date: "2009-10-26",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b2d6615c939b20388c99f6ebc108f640-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea30377840f4ef9ac62406c5e16e9c4b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What Is Love",
    artist: "Haddaway",
    id: 61590162,
    release_date: "1997-08-02",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2302ab34f24fb232d1113e7db7910e8e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9f52e7fe0c253a6586800dc85fd93458/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Live Is Life",
    artist: "Opus",
    id: 6973073,
    release_date: "2000-02-02",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98624778e2ca962b64c6dfba792ba1f7-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/decd1e28e78e8346bf6340504ad7e7e6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Brother Louie",
    artist: "Modern Talking",
    id: 548510,
    release_date: "1986-12-18",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3d4ce152d923b5c643496713bea7e5e0-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f5ab7814eda8bdbab33e9bb6941655f3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're My Heart, You're My Soul",
    artist: "Modern Talking",
    id: 985538,
    release_date: "1984-04-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-84ccb4e6641619556ff9bed88ae62e8a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/826af42c59b6a9f6ced4777bbdaf89fe/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cheri Cheri Lady",
    artist: "Modern Talking",
    id: 74249000,
    release_date: "2006-10-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6faa763ea4a07ff99f9f82e273837ff2-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e087110af2549642da54098a5c14b7ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sing Hallelujah!",
    artist: "Dr. Alban",
    id: 1059093,
    release_date: "1997-06-15",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9e62cb289440aadd2997d39c96740d04-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/741671be29de2ec19538224869e51eb5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Ketchup Song",
    artist: "Las Ketchup",
    id: 476739472,
    release_date: "2003-04-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68572e6433783c8c08da48d2afdf22be-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/be45674dc35c8f974a934dc3779c7b59/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Barbie Girl",
    artist: "Aqua",
    id: 1115044,
    release_date: "1997-10-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-ce59c8a004ae528ba4745f453eb428b6-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/89c2dae2b498dc9ca9151324d02eca2d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Where Do You Go",
    artist: "No Mercy",
    id: 4096755,
    release_date: "1996-08-27",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0599596451bbd343918ad19272ef9e39-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/02f880e927fe2d0ea5c5c234ef0bd050/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Daddy Cool",
    artist: "Boney M.",
    id: 532070,
    release_date: "1988-03-31",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88a426f8fa49671e66f1251391fa8b63-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/675e0e5a4ae84e6457d513e205fc5a4a/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Ma Baker",
    artist: "Boney M.",
    id: 1049893,
    release_date: "1977-03-14",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0add920f236d45b3363f57ac13646d7e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b95e4fc13571a55f02478665f3e2ec9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gimme! Gimme! Gimme! (A Man After Midnight)",
    artist: "ABBA",
    id: 884041,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c30d80f23a9cd9564985ed347e6ab579-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Video Killed The Radio Star",
    artist: "The Buggles",
    id: 4255660,
    release_date: "1989-09-21",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f4d94ccb1522757cb7b9d605aa07619-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f436d427e93542fc52cd077215ae95a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "La Isla Bonita",
    artist: "Madonna",
    id: 678372,
    release_date: "1986-06-11",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9b1db108fdac3a188fc2e5021713b272-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/43a8e2b1b035391f58b0927cf1040bc4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Voyage voyage",
    artist: "Desireless",
    id: 4298172,
    release_date: "1987-12-13",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6fe8d45b77c4632fd7015ccbe7d797a5-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3fcbfc72be5ad78fa6277eb5dc5df8d3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Big in Japan",
    artist: "Alphaville",
    id: 756850192,
    release_date: "1984-01-12",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dfcbe0efa2777499c8fa934dfa5e6a99-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4045858b4e63ddeb52d89f3705354673/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Believe",
    artist: "Cher",
    id: 786717,
    release_date: "2005-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e65c59b2245a6ebd33e25da0313d03cc-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/af263778ad6ef2f3f5be381292ed02a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "On The Floor",
    artist: "Jennifer Lopez",
    id: 10496671,
    release_date: "2011-05-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2f5777ceffe47adfe7b77fae17c894df-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91a0ad607c0ed67831326f055f66afdf/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Hips Don't Lie",
    artist: "Shakira",
    id: 75859436,
    release_date: "2007-11-13",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-960a51d3bfe9fc778660380879c0b3b6-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/edae3ceaf89afa1159c999fbafc6c836/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bailando",
    artist: "Loona",
    id: 1102129952,
    release_date: "2014-10-11",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f4543313332f32663da3d162cc34579f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a882f510354221bce39fde00397f0c29/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Meet Me Halfway",
    artist: "The Black Eyed Peas",
    id: 20308221,
    release_date: "2009-06-08",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-23ac8752f4d5dfbb172545646ef54751-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc345319311ade3a7f0967aa3b582e3d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Haus am See",
    artist: "Peter Fox",
    id: 2200206,
    release_date: "2008-09-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f657f7ad5c899817bd9d78200689d46d-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67fe414d2cf5cceab6429147fa37f6bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Alles neu",
    artist: "Peter Fox",
    id: 2200204,
    release_date: "2008-09-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c3c92ea339d2518e0fc898693f16853f-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67fe414d2cf5cceab6429147fa37f6bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "In the Year 2525",
    artist: "Zager & Evans",
    id: 392461892,
    release_date: "1969-07-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e73fa0ed6aa1c2bd034365b94687b0b2-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f094284f1086d3202a49537c3e7caa1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Skandal im Sperrbezirk",
    artist: "Spider Murphy Gang",
    id: 3123329,
    release_date: "1981-12-23",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9c5048c7db3743830f9a9f3cdcd2b88-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ba80f264e3b7c2426e409399d3c22f84/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I've Been Thinking About You",
    artist: "Londonbeat",
    id: 1056486,
    release_date: "1995-11-20",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b177fcda4a25831b719643a9366a42b2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/619122ac7d43050d8e2e65b4d8fd7e03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Go West",
    artist: "Pet Shop Boys",
    id: 3147740,
    release_date: "1996-09-18",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a069690aecb9e1fbd20d8430f17352bd-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dfc1edc4b84659467b3b2bd556de7fc7/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Joyride",
    artist: "Roxette",
    id: 15710900,
    release_date: "1991-03-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-27ef7a36a535f908ec0073f72d0caf18-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8c62bc59111088c3464be95fd286399/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sleeping in My Car",
    artist: "Roxette",
    id: 3092174,
    release_date: "1993-03-24",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4cc07c1ad0a1fb665144aa2dafb197eb-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/795b45e8a76e80ef4c342b00f9d37845/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Waterloo",
    artist: "ABBA",
    id: 884046,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-83e3a77be661e984e65dea8d1104abfa-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kung Fu Fighting",
    artist: "Carl Douglas",
    id: 131285694,
    release_date: "1978-04-29",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b1080d1b669622c788c6117d0d014762-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/419a1541716064e3952cc9b38f47a54b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Back To Black",
    artist: "Amy Winehouse",
    id: 2176856,
    release_date: "2006-10-30",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a4e5373f108d90b63361839339184bf-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ce985e6653c350c6e6fed077ab7d0d4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Rehab",
    artist: "Amy Winehouse",
    id: 2176852,
    release_date: "2006-10-30",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1db87b6fd567e5d9e337e942cfc7d936-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ce985e6653c350c6e6fed077ab7d0d4/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Family Affair",
    artist: "Mary J. Blige",
    id: 1161679,
    release_date: "2006-12-14",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ef9456b04eeefbe8d5bfe21cd4df94b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a3939a5b62df412e83d898dfd85035c9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Fallin'",
    artist: "Alicia Keys",
    id: 959183,
    release_date: "2001-06-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f977287578b42566feccb2b68f6ba0cb-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d83318951e3899a096ed96c6fd422032/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "One More Time",
    artist: "Daft Punk",
    id: 3135553,
    release_date: "2000-01-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e77d23e0c8ed7567a507a6d1b6a9ca1b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e018122cb56986277102d2041a592c8/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Umbrella",
    artist: "Rihanna",
    id: 925106,
    release_date: "2007-06-17",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3b1142ec5a98567c5d286a9050a85d1e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/91276466fbc876d96be9e6926060af60/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Boat On The River",
    artist: "Styx",
    id: 61286644,
    release_date: "1979-04-08",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4e0c364518cf931a8d1490ac769dcd44-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/87bef520784bf1c12a498ef45570f2b4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Let Me Be Misunderstood",
    artist: "Santa Esmeralda",
    id: 3083376,
    release_date: "2003-09-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6050535d3e7a8c24eb326d7a72b72900-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6165b7abb2f52d4f758dd4542dcc4dcb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Always",
    artist: "Bon Jovi",
    id: 538586982,
    release_date: "1994-08-10",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-92059740f22ea74367426ccadead8c0c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f513327c13908b0def56f1fa0ca79789/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Walking on Broken Glass",
    artist: "Annie Lennox",
    id: 1048702,
    release_date: "1992-04-02",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0f179b22ad0d95458070933b6ef59606-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/137bb5655a30cd9cff6e3cb56001ed7a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Have I Told You Lately",
    artist: "Rod Stewart",
    id: 2550674,
    release_date: "1988-11-17",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b440ab344e6b14f0ac88df4efea5dcd0-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc17008ae06cccd392a2312c8f30bc9c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm of My Heart",
    artist: "Rod Stewart",
    id: 3605543,
    release_date: "1988-11-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db7377912995a8185f1a0394bed6e4dc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bc17008ae06cccd392a2312c8f30bc9c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rhythm Divine",
    artist: "Enrique Iglesias",
    id: 2604912,
    release_date: "2008-11-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9da64cd913a4780abad50a792d35b27d-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d5909ac32e17d6f7c12cc1443aa88428/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Absolutely Everybody",
    artist: "Vanessa Amorosi",
    id: 111314264,
    release_date: "2000-10-20",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-433cc83c7394ad772ca74cfdaf2a14d8-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ca6de5b0bed98fc49eac7b6760bb2fdb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Butterfly",
    artist: "Crazy Town",
    id: 13144808,
    release_date: "1999-10-22",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5be18aa169ddb0a4ab2feacdc90c5daa-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3baaace858e4a31dbbe339b5e3584e21/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Jesus He Knows Me",
    artist: "Genesis",
    id: 3129672,
    release_date: "1984-04-01",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5836a863d3b16a063b10c92e205472fb-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bfb419853af09096dadd54d7c84d955d/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Frozen",
    artist: "Madonna",
    id: 4209669,
    release_date: "2009-09-18",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80c934e33b8fbffb1a60b96386cf5f54-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/195d3d2c1196b20711cfb6ad76e1c2ba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "MfG",
    artist: "Die Fantastischen Vier",
    id: 111844700,
    release_date: "1999-11-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6a75cb9dca0706fd76ea436b9395c01e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f2be3ef09864973a64c60693c0f9f14/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "November Rain",
    artist: "Guns N' Roses",
    id: 1169683,
    release_date: "1991-09-18",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5cfa1aa622ab58ca57cbe9f32dad40cf-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/546fe1eca5d3bf48c6a48fb3c0ddac63/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "All You Zombies",
    artist: "The Hooters",
    id: 5817104,
    release_date: "2001-09-04",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7691523c56da23cd9afda2d7a6e71c64-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/35fe2c643d2f00ecf78cf16def7eae99/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Shadow On The Wall",
    artist: "Mike Oldfield",
    id: 3978858,
    release_date: "1989-06-08",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3a196512ceaef7b5efe44098bc0e4807-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b3e7d77cf4454c3de4aad41fa9abdb4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Egoist",
    artist: "Falco",
    id: 3458985,
    release_date: "2003-03-03",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b365d4d080b01a65610e0a83e45fd7e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2aa6b348a02b22ba0c981712af14f485/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Just Another Day",
    artist: "Jon Secada",
    id: 3359659,
    release_date: "2005-12-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ddb85833367c4241be271f918a5267b-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a6f22e152a4a4f2c659b57b4b3789ce5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Was Born To Love You",
    artist: "Queen",
    id: 13499093,
    release_date: "1995-09-05",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-80868c1055bc334214e3b7eac68a9190-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/20adfb09ef5afd27ddcdd830780efcf1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Enjoy the Silence",
    artist: "Depeche Mode",
    id: 68515055,
    release_date: "1990-09-28",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9ca315f3bf66995386dffe05b7b0cb8-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e73716d037ee24f1331a8c0332526590/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Keep The Faith",
    artist: "Bon Jovi",
    id: 74628107,
    release_date: "1997-04-05",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-770478e24233a54b7e636a9827730ae9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a2821c5cfa61955ddd982c15cc21882/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Everytime We Touch",
    artist: "Maggie Reilly",
    id: 665239482,
    release_date: "1992-05-10",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1bd84baf4c244772a1b9c7597e6c1796-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b088365895312b9e384ca187f10ae6f9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Ordinary World",
    artist: "Duran Duran",
    id: 3130430,
    release_date: "1993-11-09",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-13f95a8c35fff35b9b81907d47313ffa-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ed10ab812e802c87e423db20800f678/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Out Of The Dark",
    artist: "Falco",
    id: 13569299,
    release_date: "1998-09-09",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f1dd9d15d2081b2b2bb1431b636628c6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ce857515b6285c7d08d4d264a52fc5e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "If You Had My Love",
    artist: "Jennifer Lopez",
    id: 15475926,
    release_date: "1999-06-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-67982c16d806e6654446aa62ed9737c7-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e9e59d8b00661ff2971cf7e62e1d780c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Maria",
    artist: "Blondie",
    id: 3151637,
    release_date: "2002-09-19",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8810e9739316335d6f7b4e850c007e7f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dc16bfe0516e13b247098f9fcea35acf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Larger Than Life",
    artist: "Backstreet Boys",
    id: 13141169,
    release_date: "1999-05-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9eb8ace7bf0af462cff5a985589f4b4f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d61eaad8f321ea876a5f5c7219aae892/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You'll Be In My Heart",
    artist: "Phil Collins",
    id: 561875132,
    release_date: "1988-10-05",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fd2bbbd275f724c93136b5b98272b015-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f48385948df5ed9a8e00c647a87e3a9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Breakfast At Tiffany's",
    artist: "Deep Blue Something",
    id: 2315711,
    release_date: "1993-07-19",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa35a624dce9f767315d0653dc12e48b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f27d35f211f3e2975176f41bd8ac079b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Baby, I Love Your Way",
    artist: "Big Mountain",
    id: 846215,
    release_date: "1994-07-19",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc0e4cbdf5ab9859bda25bd2a4335952-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bd1a6798645fb5cc63fd866668e8faa1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss Me",
    artist: "Sixpence None The Richer",
    id: 134116940,
    release_date: "1998-01-20",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-038591e63d61995293b17b85ff5ce11d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c37356b5ada5f3a79689653f7a6f5067/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Chiquitita",
    artist: "ABBA",
    id: 884038,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87c8c0beca59195392794e9873a238ba-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Red Blooded Woman",
    artist: "Kylie Minogue",
    id: 6588267,
    release_date: "2003-10-27",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1b7eca883c229f974ed8022de6164be7-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/49f20e9e74c0b91060792c889f8d219c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Ka-Ching!",
    artist: "Shania Twain",
    id: 2543341,
    release_date: "2002-11-19",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f8cf2e7e9215c9c2b7e226352dc5e002-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/86db043638625fc894a2b0d2e09c21f9/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "La Bamba",
    artist: "Los Lobos",
    id: 3819736,
    release_date: "1986-07-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff6f435824bfaaa9b7311e39419b7cbe-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d0e784e5cfd9717676fc9837b88921cc/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Holding Out for a Hero",
    artist: "Bonnie Tyler",
    id: 82299358,
    release_date: "1988-08-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2591683daa167543364c69d8d6863085-1.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0768a04e291fee32f7b474e18796ac37/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "All Good Things (Come To An End)",
    artist: "Nelly Furtado",
    id: 1151152,
    release_date: "2007-03-08",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-72308fddf4c471b3523ec4f727e9fcd9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1d3625d9f19527440769e7f8cc09db85/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Can't Hurry Love",
    artist: "Phil Collins",
    id: 134036220,
    release_date: "1986-10-14",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f9de0fd2ce8ec23b5267af65c94a8700-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Should Be So Lucky",
    artist: "Kylie Minogue",
    id: 137232330,
    release_date: "1987-08-24",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-838dbf395ba7d3091fc50075b0835d3a-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a4329d5d528ceda7b2e9c73080b69557/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Works Hard For The Money",
    artist: "Donna Summer",
    id: 34180931,
    release_date: "1983-05-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec842c804215b28adf6a4f5d1b415152-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/85494b32954ac1611bbdc51e991f6356/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "We're Not Gonna Take It",
    artist: "Twisted Sister",
    id: 3906972,
    release_date: "1997-06-26",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9f3968b16b0980bed352f36e853c3956-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3d821765fe1cc4078cddcc23c46c8f72/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Midnight Blue",
    artist: "Lou Gramm",
    id: 5611979,
    release_date: "1987-03-03",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b9b0eaec1f9dc1c4a1f92e17e1807c46-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/eaafbf81ad6f979b765ae2721bd48486/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Midnight Lady",
    artist: "Chris Norman",
    id: 61509261,
    release_date: "1986-05-17",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d389af2a40de05e3e182f34084fadfcc-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0ec87c06a02598f11b3884417cfdceb6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Whenever, Wherever",
    artist: "Shakira",
    id: 15211178,
    release_date: "2001-11-13",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5dc7f5193bce7ca1629c3e2e53c04639-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3c50690401eed099df05e72f5080af1b/250x250-000000-80-0-0.jpg",
    genre: "Latin Music",
  },
  {
    title: "Alejandro",
    artist: "Lady Gaga",
    id: 4709938,
    release_date: "2009-11-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-269bb724b66c421cc60de7bd302b1015-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62f368993ddb68c5364cd03221d07ac6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Can't Fight The Moonlight",
    artist: "LeAnn Rimes",
    id: 75779409,
    release_date: "2001-01-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-203b75ffc2f145e13298b84413c243d6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7b6725e19d0c57ba87d2e9a00ee7be5b/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Californication",
    artist: "Red Hot Chili Peppers",
    id: 725274,
    release_date: "1999-06-07",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cc72b44cdc562435438c87d94188faa6-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5e61e8290a4d1d64ca58920656c9602d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Love Don't Cost a Thing",
    artist: "Jennifer Lopez",
    id: 618317,
    release_date: "2003-09-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dc780d6da32c84ca4b6794dd5e5dc023-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6385c391e17a1c16beccb7ef7da976f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Oops!...I Did It Again",
    artist: "Britney Spears",
    id: 582254,
    release_date: "2000-11-08",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e7c043c0801ad2c4defcdbd97b9da450-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34265ac018fe0d973eac131fe04038a2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Bye Bye Bye",
    artist: "*NSYNC",
    id: 632447,
    release_date: "2000-03-21",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b60e6761708b4f5815d105825fba23f-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/01fdca2ecf54d678ff005162b5b6cc92/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Eternal Flame",
    artist: "The Bangles",
    id: 3037340,
    release_date: "1989-04-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eb9fa8068bd8050b1d7d4de10c25ee29-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/160a720272bfeac612989f32ae101c0d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Poison",
    artist: "Alice Cooper",
    id: 626178,
    release_date: "1989-07-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-87dbfcaa362a42d9a8c03ec08e178904-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3640fee637a166da4ccc65246467b560/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Bed of Nails",
    artist: "Alice Cooper",
    id: 468471872,
    release_date: "1989-03-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0874eb414e7b2244f0f6ac08d822618b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d4638ee8af1ba13ff7ad9c456ca076c/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "St. Elmos Fire (Man in Motion)",
    artist: "John Parr",
    id: 6410074,
    release_date: "1985-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7bdb0019908b327fffc6cf2bfa3b410c-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9e8322c515724598c97710aa54ef8be/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Here I Go Again",
    artist: "Whitesnake",
    id: 576902402,
    release_date: "1982-10-06",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-beadb5075d84b9728419b247dfbfcb49-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/84a375b90c2751bb7b4e6effb4d7e2d1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hey (Nah Neh Nah)",
    artist: "Vaya Con Dios",
    id: 58667011,
    release_date: "1990-09-27",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f5190fd55152c696b269d2a3956d74ff-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ea6c274ad45ba5835111eb2e5d8b148e/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "You Keep Me Hangin On",
    artist: "Kim Wilde",
    id: 74640929,
    release_date: "1986-09-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-628f70a3efa583cb4d7ed1edc4723227-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/34145a9345aa92437fae5db912e7218f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tell It to My Heart",
    artist: "Taylor Dayne",
    id: 444738122,
    release_date: "1988-01-19",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7ee6c38109d745d289c3466e9f310ddb-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/130089f12fa9ceaba703a12043b44860/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "It's a Sin",
    artist: "Pet Shop Boys",
    id: 3122973,
    release_date: "1987-11-04",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b2620a8c6043e584233fe7dae90617e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5453ca81ae47f016b0aa7b55729da928/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Le Freak",
    artist: "Chic",
    id: 67359668,
    release_date: "1973-05-20",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-ec55cb0edfa06119f7283972ab45a228-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a5b56f3b2533c5748cf985447def437/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Superstar",
    artist: "Jamelia",
    id: 463394462,
    release_date: "2003-06-30",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e7001d7c2fb38e1254fbe79c430109bc-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/aaa7a43bfa31f931f46895a6650b0c50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Down Under",
    artist: "Men At Work",
    id: 1030221,
    release_date: "1981-02-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e132010c91e0223e985b5891dedfa4a8-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/968a09e7739dfeee3448a1a79555504a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Tearin' up My Heart",
    artist: "*NSYNC",
    id: 15477160,
    release_date: "1997-05-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3ca06b08807bbc733f897d04ef6245fb-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6a4958616c62ba71889a075ce84db82a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "September",
    artist: "Earth, Wind & Fire",
    id: 487484142,
    release_date: "1978-04-17",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c851383748376218a9e00a3f3a1ba25f-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18f41ecd3781fb96bffa2b0b49955db5/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Lay All Your Love On Me",
    artist: "ABBA",
    id: 76376863,
    release_date: "1974-04-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-16f55e7ae5cf828766ec4d9e938442c7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e28b2ad6ec55276c66dae8297c686c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Sweat",
    artist: "Snoop Dogg",
    id: 10162588,
    release_date: "2011-03-04",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e55fe66ca1a72021f87cb75351e59d17-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81151ad27acdf3d3139e9c3dbf875ff/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "The River of Dreams",
    artist: "Billy Joel",
    id: 548676,
    release_date: "1993-04-03",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-25e67471c38cf7213752d2d7b65587da-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4586382c3e3c536105b138f5a9ff7443/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "How Will I Know",
    artist: "Whitney Houston",
    id: 1131192,
    release_date: "1985-02-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cda4ae9c587b5fff000e8239d0e38538-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e5712b346f0ca0f167da8ffd7588db3f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Alive And Kicking",
    artist: "Simple Minds",
    id: 58831541,
    release_date: "1985-09-24",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e79992b1416afde60254565a656bcb53-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b2f8d7c1463685f5c3c7c4d78c4c5e2d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Voulez-Vous",
    artist: "ABBA",
    id: 884040,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-21b0b52ef0f3641b556fb5203e574c63-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rebel Yell",
    artist: "Billy Idol",
    id: 3140135,
    release_date: "1988-06-23",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-14a1efbd189826889cfd4d753172cfde-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cdff56843117adeb102f5c9f490295ec/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Rain In May",
    artist: "Max Werner",
    id: 96538266,
    release_date: "1981-03-30",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b37df0dd997895cff73577d71b84e446-0.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/61b69b3b041385b3da54881e5a5c4e03/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Goldener Reiter",
    artist: "Joachim Witt",
    id: 700835,
    release_date: "1998-10-26",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c854cf6f271e96691ee3dbd2ebf4c5f0-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5631571f81b8fbd5d318bf22051fdf87/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Such a Shame",
    artist: "Talk Talk",
    id: 3257061,
    release_date: "1982-06-26",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a5e25b2dca98253674770a8666f6d2ef-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/18298b61298f0355c69a8038829287a9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Shut Up",
    artist: "The Black Eyed Peas",
    id: 953676,
    release_date: "2003-07-31",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5ec8da3e0dd60c1d71f5335be6640a7c-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6efe94615f21a80c1ad670741e86e482/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Push The Button",
    artist: "Sugababes",
    id: 4307900,
    release_date: "2009-09-28",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4aadc64e0fe19c551658e8864391d279-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/291c516d3d6b2d70618302cffd9cb48c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jenny from the Block",
    artist: "Jennifer Lopez",
    id: 628099,
    release_date: "2002-11-11",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5e250f3ba7b9cee766cbb1cb8b1f370e-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f60d5198e5a3deb22b3312bafb6d314c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Riddle",
    artist: "Nik Kershaw",
    id: 1174772,
    release_date: "1991-11-25",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b71318f4be8ca6a156012881e404973b-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ce29a69eecc2c08d2b772bf0c5cca02f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Johnny B.",
    artist: "The Hooters",
    id: 1081050,
    release_date: "1987-07-19",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dbfbebf03e58135631258e3a4d1566f2-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed86407d9712c21e5177390822daf4b3/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The One and Only",
    artist: "Chesney Hawkes",
    id: 130195442,
    release_date: "1991-04-13",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ac6cf839e3ccda11794d071205420359-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/233b1e9651543a6fceaa9fc2f10087a1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Carrie",
    artist: "Europe",
    id: 858447,
    release_date: "1988-09-16",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88e918696d50dbf16e98bf79ab274ffc-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2971537322dde7f40381a8586c08cd8e/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Wind Of Change",
    artist: "Scorpions",
    id: 927900,
    release_date: "1991-01-07",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-38fc0c44aa0880cf58163dce12a2a5b4-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d1724dd3ac61f2e1df593c543ce2b22/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "What's Up?",
    artist: "4 Non Blondes",
    id: 2184743,
    release_date: "1996-07-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2d13de786b154b10be49cb7827af47ac-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/22ab04ebf46e75a8c2c83a774c8831a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Of Us",
    artist: "ABBA",
    id: 76376886,
    release_date: "1974-04-07",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c5eb8157e41e3673739f7e2954fd4cf0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e28b2ad6ec55276c66dae8297c686c6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Money, Money, Money",
    artist: "ABBA",
    id: 884036,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95c1fe6523b63bb79e9dc438ca5f9176-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Born This Way",
    artist: "Lady Gaga",
    id: 11747931,
    release_date: "2014-12-18",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79ebe6317e5684b616baf7fef3420565-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0f256c4bebac58a7c4122e0e639303a8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Dance into the Light",
    artist: "Phil Collins",
    id: 134036268,
    release_date: "1986-10-14",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9e930231969b2d4717e9dcb7571488d5-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "No Son Of Mine",
    artist: "Genesis",
    id: 3145758,
    release_date: "1987-10-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fc8805248bb9158803fb431175054530-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ab6bae79d41a6d8b765fe0f006302bca/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "A Kind Of Magic",
    artist: "Queen",
    id: 13499039,
    release_date: "1986-09-05",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-61a8c79eb435466b15efe82556eca10a-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/518738a2c485a7ea27ef52ded999902f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Don't Dream It's Over",
    artist: "Crowded House",
    id: 133577580,
    release_date: "1986-10-07",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f51faf5f52885fa0db849001c5868549-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/797cfed6ecc569b95afd34ba55784fba/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Fernando",
    artist: "ABBA",
    id: 884039,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bcee896ddc60f1296b8128d58b4515a5-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That's the Way It Is",
    artist: "Céline Dion",
    id: 14552286,
    release_date: "1999-11-07",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-175572757c7123685ef489988edbfc93-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/177346106112348e15ec4f9add0c1ef4/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "That's The Way Love Goes",
    artist: "Janet Jackson",
    id: 3501645,
    release_date: "2000-07-06",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cdafaaee94e59f88fbdd0f3e913b26d1-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4104f417dff07ee2b6c49b798f8b23ad/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "One Night Love Affair",
    artist: "Bryan Adams",
    id: 88902731,
    release_date: "1994-11-24",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-223550d4e19162a27101d20dfda7f5e4-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7be525c05b1942e1844d0073f7b9ab5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Up Where We Belong",
    artist: "Joe Cocker",
    id: 906585,
    release_date: "1982-02-18",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9985131650715bf3f6bccee67066dc8b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dae9c863ba27686af441666135693de2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "True Colors",
    artist: "Cyndi Lauper",
    id: 608820,
    release_date: "1983-04-23",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7b242487082e692b65d85daec9bbd529-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ed1fbfa7f334b65bc021be93cb79157f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "She Wolf",
    artist: "Shakira",
    id: 75834066,
    release_date: "2014-03-07",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-696234a467163429cadd6bba8e11356a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3686fe46b249a6c0f51809cd5685a024/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Engineer",
    artist: "Animotion",
    id: 17978474,
    release_date: "1996-04-16",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0befa60752453e93a297610aba5ec595-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/bb6bb80759cb246cc49e1ea8cb7ebf2c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "More And More",
    artist: "Captain Hollywood Project",
    id: 143154464,
    release_date: "1992-07-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-5bedd004066286ab1c24d00a47aade1c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/55053ac3f523c231fb7850e67731b9e6/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "Lemon Tree",
    artist: "Fools Garden",
    id: 72665258,
    release_date: "1995-11-15",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dd5f56d3a4856599cf4c1c6439a3888a-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b0cb03a865c85e596a610166819dc7b0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gold",
    artist: "Spandau Ballet",
    id: 3130318,
    release_date: "1980-05-15",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a5126cb165999b76362167d3d5d7ef65-19.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b88d3c3ad23435fb5b8d52038551c1f6/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Stars",
    artist: "Simply Red",
    id: 6636947,
    release_date: "1988-02-22",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1fb3477a2dccd5a8fdaaa975a6853c17-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0859b2389fd038c2f764a0d7166e1ddd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Something Got Me Started",
    artist: "Simply Red",
    id: 75814726,
    release_date: "1992-10-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7af526068827296d6540b510bcd9d710-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6082ea7f45ec9a7093225b85bd6ebadd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Femme Like U",
    artist: "K-Maro",
    id: 605348462,
    release_date: "2004-12-10",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-54728df8bf2caead57ea8b6cce3bc58e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3b1257498a1c2a50c704f27bb308c6fe/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "If You Don't Know Me by Now",
    artist: "Simply Red",
    id: 75814729,
    release_date: "1992-10-01",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1dd59d2fe26facc7ec732030cdc84d76-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6082ea7f45ec9a7093225b85bd6ebadd/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy",
    artist: "Seal",
    id: 4688231,
    release_date: "1999-11-27",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-aa00b36494ff68aafb2b338bebe37995-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4a3e132b6f72670141257286a4a63c5c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Self Control",
    artist: "Laura Branigan",
    id: 4288159,
    release_date: "2005-02-22",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d6a5dc085c3710f46710d518a804349b-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8d7d2bb9f156110a5be3734c246c99a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "New York Groove",
    artist: "Hello",
    id: 59207391,
    release_date: "1976-02-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-064c205116447588c86cc5b23e538b92-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fcdf815b71950c14d18b54d2ef0eec15/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Axel F",
    artist: "Harold Faltermeyer",
    id: 17933254,
    release_date: "1984-06-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-89fcaa9821fa44ca8de504a4ad272c4b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2d1630ee29a1c9ea22822a086c5a4a8e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer In The City",
    artist: "Joe Cocker",
    id: 69795749,
    release_date: "1994-03-08",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fe2cf212f3daa88891ca204dfaed17e9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7ccf682bde3b72731fb21347123ed52c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You Can Leave Your Hat On",
    artist: "Joe Cocker",
    id: 3104719,
    release_date: "1994-05-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c5bbfff7d5cabe6af7875b8fdc50df4-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/57e4c3c461961e77fab66662fecee013/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Easy",
    artist: "Faith No More",
    id: 813837,
    release_date: "1990-01-15",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-90a4340163bc806352238bce407ffba9-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b9634f0c731e31dc5f3e3c43f0771376/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Right Here",
    artist: "SWV",
    id: 555805,
    release_date: "2003-06-20",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2627da4e00955b1a1390a2391751a980-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8852e80ba157a469f6e8702170e585b9/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Freestyler",
    artist: "Bomfunk MC's",
    id: 5363024,
    release_date: "2000-02-15",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b3c72bdc22f4d99801d990e8646b2ecb-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/693d6472f1c44dd5a55432acef70d193/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Love Your Smile",
    artist: "Shanice",
    id: 910925,
    release_date: "1991-03-07",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-460e09322c6f078d0e1eb07a1b1fc46a-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/447dbf905a9bc8113add6d914babe853/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Moving on Up",
    artist: "M People",
    id: 600153,
    release_date: "1993-02-21",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4c00990367657165e695b25c81baa3ea-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/03ae0d6ea06504c955ff6661049af62b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Feel Lonely",
    artist: "Sasha",
    id: 686342,
    release_date: "1998-10-26",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f059995ff5425cf2878f3c6060cc8c1c-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/637642a15b8d354e6c3f5f08e74a81af/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shiny Happy People",
    artist: "R.E.M.",
    id: 136339500,
    release_date: "1991-11-18",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e400c9430ece7e3f1aa3e11bad1d217a-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a968ed3814acb2ef8f3929b1bebcb42d/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "The Shoop Shoop Song (It's In His Kiss)",
    artist: "Cher",
    id: 540169472,
    release_date: "1990-08-10",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-ff9545413cea4ae026caf77bc7db8899-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/06cd15444c800fc985d53489b8ca4a13/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Come Along",
    artist: "Titiyo",
    id: 19271551,
    release_date: "2007-10-11",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-96d28df59d4a917665390441034584c9-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6fcc0f75aaa955309c6377b1b6387ad9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Supreme",
    artist: "Robbie Williams",
    id: 3102420,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-dd82e93b2f2a4718c07bdcc474c69c32-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d260fa442f925e1eb1c95bb29941f636/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Whole Again",
    artist: "Atomic Kitten",
    id: 3240483,
    release_date: "2003-03-01",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f908185d140250f1766ba6f04f6c67ec-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d3af8f49852d373f99a7d9839fcacfe/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Miss California",
    artist: "Dante Thomas",
    id: 368705481,
    release_date: "1996-07-17",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a03174f79f501154a7a27f437d519e89-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e7001559164b20d486115986f508ad49/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Don't Pay The Ferryman",
    artist: "Chris De Burgh",
    id: 1158199,
    release_date: "1981-04-02",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-db8496f9cb1df91e59cb8512a40c006a-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4507359752a023d0d4e91370c779acd2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Little Lies",
    artist: "Fleetwood Mac",
    id: 664456,
    release_date: "1987-01-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7339f72f30c8c079c94fa573db2b6cc3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fae8305ef18ac327a4c19436672d5d5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Summer Wine",
    artist: "Nancy Sinatra",
    id: 4356542,
    release_date: "1968-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e3e8ca343add54784addb8367a967c7b-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fcf8669f7aebb982798cf026aa6b872b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "'74-'75",
    artist: "The Connells",
    id: 84382469,
    release_date: "1993-07-29",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a0d755c15965c7b4a046486dfc278fc1-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c6c7515591bb1d506e22853ee4b6817f/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mmm Mmm Mmm Mmm",
    artist: "Crash Test Dummies",
    id: 6075100,
    release_date: "1993-05-10",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e4517ab7e527526d73a789e20f2c346f-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b67d4aa649454a55fbd405398cd14975/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Runaway Train",
    artist: "Soul Asylum",
    id: 1057312,
    release_date: "1992-01-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8cb734d80dbbee0c473165d313789712-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ad93113b7bbb0061171aa3db8a680696/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Cats In The Cradle",
    artist: "Ugly Kid Joe",
    id: 1115742,
    release_date: "1993-08-19",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c25dacbc712b862b8e9defd9dad1e36-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/04fd7d9a9a9a00cb7f69d9e3a5509dd1/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Moonlight Shadow",
    artist: "Mike Oldfield",
    id: 3978859,
    release_date: "1989-06-08",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d60a78de6faa4a97880f99b078a774d8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b3e7d77cf4454c3de4aad41fa9abdb4/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Gimme All Your Lovin'",
    artist: "ZZ Top",
    id: 2133998,
    release_date: "1983-07-08",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-95935b129b3f471ce8f64cf3519b6be7-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/706d994a9b906b857db6869183c30274/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Americanos",
    artist: "Holly Johnson",
    id: 4275259,
    release_date: "2009-09-28",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c934500c167061bb88c10dddf804ce76-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6827672c0ca3c851f602191c281f1b10/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kopf verloren",
    artist: "Peter Fox",
    id: 2200207,
    release_date: "2008-09-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3c86475b4865bfe5b452e36a42094578-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/67fe414d2cf5cceab6429147fa37f6bd/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Lonely",
    artist: "Nna Darkman",
    id: 1357486852,
    release_date: "1997-04-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-265f8b1867704fbfa28a8c3648a69977-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6f58520da1f69d770e2f91144dc39fc2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Played-A-Live (The Bongo Song)",
    artist: "Safri Duo",
    id: 2276002,
    release_date: "2007-08-09",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-504a698fa3ce6847d27270f24a578d8e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/411921710089486f9c2f1d00247bd9f2/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Heartlight",
    artist: "Kenny Loggins",
    id: 15526148,
    release_date: "1983-09-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6cd0c63cb8667635c1255c6cf0836de7-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2f45d298a7d06d5b4809d91445976adb/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Rivers of Babylon",
    artist: "Boney M.",
    id: 968798,
    release_date: "1988-05-12",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46c13baa1335824ec4392902b69be7e4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/24290d29032e06a3669ab17c5e1b7a2a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "We Didn't Start the Fire",
    artist: "Billy Joel",
    id: 626123,
    release_date: "1989-10-17",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8030aa3bd790b58875dc78a5b2663067-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8a44cff0ab7a842716dc62235a211a30/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Centerfold",
    artist: "The J. Geils Band",
    id: 3168236,
    release_date: "1989-09-20",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c7ec8ba67c79b610f53e989c2f1a449e-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc1722705a41d9221bff919895c236bf/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Born in the U.S.A.",
    artist: "Bruce Springsteen",
    id: 15586213,
    release_date: "1984-06-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0a314576f8bdddadd140e7e390d0bcbd-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/106f651bf559796879b90e83c50b3e96/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Hungry Heart",
    artist: "Bruce Springsteen",
    id: 15177141,
    release_date: "1980-10-17",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f23262581f49e5dd98f80c0ad5a4d8d2-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a971f0ed2fda6d017adeb5e3af3c1619/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Boys Of Summer",
    artist: "Don Henley",
    id: 3977905,
    release_date: "1984-06-16",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2ad1f48a05d8d7b6fc26ab7f96dc6482-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e374e05d5e2991dda20e1b2636e69cc9/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "The Wild Boys",
    artist: "Duran Duran",
    id: 3130438,
    release_date: "1984-11-09",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-de36420696344b861fccc9e155e72d04-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3ed10ab812e802c87e423db20800f678/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Liberian Girl",
    artist: "Michael Jackson",
    id: 59509561,
    release_date: "1989-09-14",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb1ab447320896a59c6b632fcb2435c1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fb0a49a91ab8137ec9d028c4dbb04021/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Leave Me Alone",
    artist: "Michael Jackson",
    id: 59509521,
    release_date: "1989-09-14",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9d36dadaa26a4058772825563dd8e06b-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/995b33eaad9a62409c822ce9c670e542/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "ABC",
    artist: "Jackson 5",
    id: 920082,
    release_date: "1996-01-30",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-096452f45cdca8793b4f01d33189c559-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/441713fba5bca16b3731767218c27234/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Suspicious Minds",
    artist: "Elvis Presley",
    id: 1055776,
    release_date: "1969-09-28",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-734d74845023ba9faa12ede425cabd91-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3598c1cdd425022000eba13ade236e8d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jailhouse Rock",
    artist: "Elvis Presley",
    id: 6596867,
    release_date: "1957-08-30",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e0eab947e665fddbb092d5964b28df9-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/ddd830fa6768774fdc110e4388b88992/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "MMMBop",
    artist: "Hanson",
    id: 88685889,
    release_date: "1997-05-26",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-236f6b7aeb474815bbbae717956e5e6e-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/394c44c400a9786a9582a7fed308622e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Smells Like Teen Spirit",
    artist: "Nirvana",
    id: 13791930,
    release_date: "1991-09-26",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-358eb79e55e30b4719d976e15d41e230-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f0282817b697279e56df13909962a54a/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Mamma Mia",
    artist: "ABBA",
    id: 884030,
    release_date: "1978-06-02",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8b71351d35c024079047ab3bc66b0fbc-16.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8b70d474b7a8f27799e0d665e9b737e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Isn't She Lovely",
    artist: "Stevie Wonder",
    id: 2802054,
    release_date: "1976-02-10",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-028af9e88956de62defabf7dc8293723-12.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cca2a5854f722f615dddf22ca9d93804/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Crazy In Love (feat. Jay-Z)",
    artist: "Beyoncé",
    id: 609244,
    release_date: "2003-06-24",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f5ecd6528459f97a68077d10a7d626e9-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1ea1a631aa5235bbd0063643beb96fa8/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Single Ladies (Put a Ring on It)",
    artist: "Beyoncé",
    id: 2485118,
    release_date: "2008-11-14",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b844f52cb55a5d993a3daf4be4b0d069-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/1f0450a010b5a825194d7ca00b3067ab/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Roar",
    artist: "Katy Perry",
    id: 71645431,
    release_date: "2013-01-01",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-805b723187e1492231c36b8847e129aa-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fe781ecd9879a82beed80f6d3e80745b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Firework",
    artist: "Katy Perry",
    id: 17135111,
    release_date: "2012-03-23",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e320159d1758c4f4728a81c3f4fe6098-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/99578b0bb8c838383c414a5b62b5e15d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Jolene",
    artist: "Dolly Parton",
    id: 114422238,
    release_date: "1974-02-04",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d28a32ff05149c935553cb0a10d4d21-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d47fc7a27a0d40cc2a8c119ed2bc53b7/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "9 to 5",
    artist: "Dolly Parton",
    id: 992937,
    release_date: "1980-11-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1aa2ac195fd4025a0db436a4c6ef5d2c-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/62438de920e4943c653d545999cd120e/250x250-000000-80-0-0.jpg",
    genre: "Country",
  },
  {
    title: "Take Your Mama",
    artist: "Scissor Sisters",
    id: 2509155,
    release_date: "2004-05-03",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-98c1f20d0972d5ce0da1b5ce1429dfe6-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/fab68ecdfc37a8ed204325d819fe58f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shape of You",
    artist: "Ed Sheeran",
    id: 142986204,
    release_date: "2017-01-07",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d8f5b81a6243ddfa4c97b9a4c86a82fa-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/000a9228cecfcc7c2093d9cd7bb66447/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "In the Air Tonight",
    artist: "Phil Collins",
    id: 134036212,
    release_date: "1981-10-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-eee21ed4de5195b63ffa0c25021e87c9-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/73e6481676a8ab3e93f0f7b79a9cff68/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "You're The First, The Last, My Everything",
    artist: "Barry White",
    id: 537988582,
    release_date: "1974-08-10",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-070df5a471f6a397993f6d613e1f0b03-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5d4d9be63470e9c842f8d504aabc8bd6/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Maggie May",
    artist: "Rod Stewart",
    id: 538641872,
    release_date: "1971-08-10",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-0d1fc907d52fadfcde07ff18dbe10611-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/964629a321bad47e2ad1d5f4c6073e89/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "CAN'T STOP THE FEELING!",
    artist: "Justin Timberlake",
    id: 124237488,
    release_date: "2016-05-06",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e2950c9eaf0c5c9bd8621dc855099f4-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/209aa560e3b5fbe679e91887fc0c7332/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Cry Me a River",
    artist: "Justin Timberlake",
    id: 969494,
    release_date: "2002-11-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-065c43d27032a85c93bf25f48f08e706-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7cba368fa8466d72d149264577cb19d7/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Feeling Good",
    artist: "Nina Simone",
    id: 1707933617,
    release_date: "1965-06-21",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c96464869490f4464b6545c5096c1c75-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2b1664b2dc4a4bd7a95aca09d67bc47f/250x250-000000-80-0-0.jpg",
    genre: "Jazz",
  },
  {
    title: "(Something Inside) So Strong",
    artist: "Labi Siffre",
    id: 6469108,
    release_date: "1987-07-07",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-02c32139016aeb0107013511147eb48b-15.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2be6d43bc57c07ccf80d7d499eaa5ac2/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I'm Still Standing",
    artist: "Elton John",
    id: 1171163,
    release_date: "1983-04-30",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4c0855ea6f93be88d6c0843689d63494-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3f099560bed3f4813a8bf71e203cd3f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Tiny Dancer",
    artist: "Elton John",
    id: 424567592,
    release_date: "1971-11-10",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-cb374939bd35ab1affe51816776899c0-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8bef764300d825a16e2eab8040cafe6c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Your Song",
    artist: "Elton John",
    id: 880181,
    release_date: "1988-06-02",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-ae8f9b10e8924ad25b2ceed76c3e48e8-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/dba55570a13a8be5d20e457a75091dd5/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "My Girl",
    artist: "The Temptations",
    id: 1156952,
    release_date: "1965-03-19",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e210a366364e907920d40326238d9dbb-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/4b491c556300c84af39e5fe07dd1e34c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Good Vibrations",
    artist: "The Beach Boys",
    id: 3091992,
    release_date: "1966-12-06",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-fbefe86c9e461c8c0a63c269a9aada52-13.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a9121464332223e7cb452040f5ddb70/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Barbara Ann",
    artist: "The Beach Boys",
    id: 3091983,
    release_date: "1963-05-29",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-07b47598f3ad1125392d5e5335b8f610-14.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0a9121464332223e7cb452040f5ddb70/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Uptown Funk (feat. Bruno Mars)",
    artist: "Mark Ronson",
    id: 92734438,
    release_date: "2015-01-12",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-629282ccbd8902762c922ccebd4c3d03-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3734366a73152d0367a83a4b09fd163f/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Creep",
    artist: "Radiohead",
    id: 138547415,
    release_date: "1993-02-22",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-4850a49917137825d128ae02a60f71fe-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f08424290260e58c6d76275253b316fd/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Fireflies",
    artist: "Owl City",
    id: 4188437,
    release_date: "2009-07-28",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-899e8078b5ddc258fe539c6b9baefa2f-25.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c923db2cb6698897426be066e01086c3/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Where Is The Love?",
    artist: "The Black Eyed Peas",
    id: 1697351967,
    release_date: "2002-04-01",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-ddd90e1fae94e3aeb003eb69c7e99766-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/0e4b70f9985801a0acbcef1782bd18eb/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Cupid",
    artist: "Sam Cooke",
    id: 1653404212,
    release_date: "0000-00-00",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3cd927b6ed2b16803a7e1a0b4b03c85f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b125979fdadeeaf39435061ae3d53f6b/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "I Wanna Dance with Somebody (Who Loves Me)",
    artist: "Whitney Houston",
    id: 75981528,
    release_date: "1987-01-01",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-653b4a72ba9d8f4e8cfaca53428ca84d-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/26b5030b971a5fd363f82cad8cff7453/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Vincent",
    artist: "Don McLean",
    id: 3166507,
    release_date: "1987-04-24",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-beb57aac1bc6ca269a7a45c1a6f45721-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e8514b388d88fabe7e094b4d167c583d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "What A Wonderful World",
    artist: "Louis Armstrong",
    id: 2538209,
    release_date: "1967-05-27",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-90eeb04333f1493438ea659e07f9c465-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7dcaac79890bdda6a2e618c323351c38/250x250-000000-80-0-0.jpg",
    genre: "Jazz",
  },
  {
    title: "Stay With Me",
    artist: "Sam Smith",
    id: 111780376,
    release_date: "2015-02-18",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d14a91affabbfb75831a75c357b9cdfa-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/231c66c0ed260c708163eff3bb8458da/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Titanium",
    artist: "David Guetta",
    id: 62624905,
    release_date: "2011-08-24",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-79335512785dc23ab9515031e03adb08-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/7d4a24e294bb3c228ad96dd75512e2f0/250x250-000000-80-0-0.jpg",
    genre: "Dance",
  },
  {
    title: "All About That Bass",
    artist: "Meghan Trainor",
    id: 113420702,
    release_date: "2015-01-09",
    preview:
      "https://cdns-preview-c.dzcdn.net/stream/c-c0df24413d8585eae3a220afe38e544c-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/14f6b5d6ab06d3bc46b15c67c15305f1/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Call Me Maybe",
    artist: "Carly Rae Jepsen",
    id: 17826508,
    release_date: "2012-05-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-af92f4b745c8c46b73317d4240e78982-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/d59ba2295d91292a8bcb372bad9d88be/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Empire State of Mind",
    artist: "Alicia Keys",
    id: 4761593,
    release_date: "2009-12-15",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-1f4fc0d4a1a138682469073181552417-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/74406abeb7ba1e28ebf4a7e7a01c4e1f/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Save Tonight",
    artist: "Eagle-Eye Cherry",
    id: 2252935,
    release_date: "1998-05-04",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2fb9c3cb87eb92092b981af7196b59a8-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/e266242f0f87f9f394f12b1ad3b549f0/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Wherever You Will Go",
    artist: "The Calling",
    id: 994228,
    release_date: "2001-02-11",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d387386f3889f384aeb90d4b798c84a4-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/40d325ff5bb9aa8dc5af27a3d5cf5e50/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If You're Not The One",
    artist: "Daniel Bedingfield",
    id: 2319140,
    release_date: "2002-02-07",
    preview:
      "https://cdns-preview-d.dzcdn.net/stream/c-d831f8d2e3aa7f48c1f51d10c3743627-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b8dc5eb14bdfb161c81b3f9966ef4ae9/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Gotta Get Thru This",
    artist: "Daniel Bedingfield",
    id: 2298587,
    release_date: "2001-04-14",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7aadc588d975c053c5fa58ce6fe68dd0-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/10e111eb47dd8b80c8b2166fa4ac0872/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Son Of A Preacher Man",
    artist: "Dusty Springfield",
    id: 1104547,
    release_date: "1969-09-30",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2054654f099b27d98a6733ec5827ea39-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/cc8846fc9a82c9223f5b1c39dddc5270/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Spooky",
    artist: "Dusty Springfield",
    id: 2267150,
    release_date: "1969-10-01",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-7a612322681e7da8a49c41d352ef308e-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5ec8da1d38abddd0406e74039bd15f90/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "American Pie",
    artist: "Don McLean",
    id: 3156285,
    release_date: "1971-06-12",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-06c4fb5b801c6ee59a284506cdfd0fa9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5a0cfdacfaaa0ba9b514f86f9d69f988/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Kiss The Rain",
    artist: "Billie Myers",
    id: 2298539,
    release_date: "1997-04-05",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-00194d3a9f0382db37f87127b3a70497-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/521c96c424f76ba822f09273a7918896/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Somebody That I Used To Know",
    artist: "Gotye",
    id: 14993990,
    release_date: "2011-12-12",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9744174e88211551e94357d7ee1f6ee7-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/875e28fbbb3a7b381ffd5839b5905445/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Waterfalls",
    artist: "TLC",
    id: 1043894,
    release_date: "1994-11-14",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e421e4e28cc565b257fde1e10ca5098e-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3134fd3fba883b4e6c8df8dc1487dbef/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Unpretty",
    artist: "TLC",
    id: 1076856,
    release_date: "1999-02-22",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2a235f55452f3b4d0e67530cfc7046e0-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/6dd5f40e7688ba155a5ef557977e95d3/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Creep",
    artist: "TLC",
    id: 1043879,
    release_date: "1994-11-14",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8f66cdb27dad259dbf10dc9c2b13c5b-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3134fd3fba883b4e6c8df8dc1487dbef/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Running on Empty",
    artist: "Jackson Browne",
    id: 3613971,
    release_date: "1977-12-06",
    preview:
      "https://cdns-preview-4.dzcdn.net/stream/c-46b07239a514eea3b6ebe197ecc679f8-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a5e537dadbff5e3528af1c7ce9495325/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "I Try",
    artist: "Macy Gray",
    id: 15175385,
    release_date: "1999-07-03",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e709c64a545ee1623dd180be062775a0-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c68c524bca29e48dff44d2eacb719e43/250x250-000000-80-0-0.jpg",
    genre: "R&B",
  },
  {
    title: "Hey Ya!",
    artist: "OutKast",
    id: 628266,
    release_date: "2003-07-08",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-adc4965ae3a8b122a10814ee15828ab3-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/f81783b6cc6030733cd475f820855562/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "Writing's On The Wall",
    artist: "Sam Smith",
    id: 107900036,
    release_date: "2015-09-25",
    preview:
      "https://cdns-preview-0.dzcdn.net/stream/c-01816014ed67406c99bf5d497270b1bb-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/75e56110a5cef30a4518a075d10ad05e/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "One Day Like This",
    artist: "Elbow",
    id: 1088130,
    release_date: "2008-03-17",
    preview:
      "https://cdns-preview-1.dzcdn.net/stream/c-153040625aeee992a083fae9ced42c3e-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/3b4d06789fb518d736d62244a9363301/250x250-000000-80-0-0.jpg",
    genre: "Alternative",
  },
  {
    title: "Marry You",
    artist: "Bruno Mars",
    id: 8011854,
    release_date: "2010-10-05",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bfa38e5adb50b74448ca0b89ea6e656f-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/5b59dc18e109515420f8237719bd2186/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Don't Worry Be Happy",
    artist: "Bobby McFerrin",
    id: 3127387,
    release_date: "1988-01-16",
    preview:
      "https://cdns-preview-7.dzcdn.net/stream/c-737eefbc710fc89681dec000170e0d25-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/121ed33882c127248831b9aacfe5220b/250x250-000000-80-0-0.jpg",
    genre: "",
  },
  {
    title: "Do Your Thing",
    artist: "Basement Jaxx",
    id: 2661885,
    release_date: "2001-06-25",
    preview:
      "https://cdns-preview-3.dzcdn.net/stream/c-3dd26406f7ff24dcc5deb9b3e28194a0-3.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/c5c187cbbd0265d34859f9116a4cc46d/250x250-000000-80-0-0.jpg",
    genre: "Electro",
  },
  {
    title: "Wind Beneath My Wings",
    artist: "Bette Midler",
    id: 3616256,
    release_date: "1988-03-06",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-68c426d924b9c35dc6cbca63b757a133-9.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/30fa0ef71608a98273700307fc40ad59/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Feel Good Inc.",
    artist: "Gorillaz",
    id: 3129407,
    release_date: "2005-05-23",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-88e048997f12d2e0ee67589307270507-8.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dd837db7a1c6acf7acb20e26c0452c3/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "DARE",
    artist: "Gorillaz",
    id: 3129413,
    release_date: "2005-05-23",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6314296e111419d818779e578b0f41e3-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/8dd837db7a1c6acf7acb20e26c0452c3/250x250-000000-80-0-0.jpg",
    genre: "Rap/Hip Hop",
  },
  {
    title: "We All Stand Together",
    artist: "Paul McCartney",
    id: 1125787962,
    release_date: "1984-11-06",
    preview:
      "https://cdns-preview-a.dzcdn.net/stream/c-a8b6681dc581cb626d07b5647167d978-4.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/b834628a1a37bf798dc6326f68edf50a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Moondance",
    artist: "Michael Bublé",
    id: 782888,
    release_date: "2003-02-11",
    preview:
      "https://cdns-preview-6.dzcdn.net/stream/c-6eed2160916290cfa7718ce5e5e8c8f9-11.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/a5dd92ae1b53ad19f39e3036dd510541/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Beyond the Sea",
    artist: "Bobby Darin",
    id: 3613860,
    release_date: "1958-10-30",
    preview:
      "https://cdns-preview-9.dzcdn.net/stream/c-9a7dc6e9cd63d9486dce17cdc242704e-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/9193897d1d59fff550bc9cc971a7eb5b/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "The Power Of Love",
    artist: "Huey Lewis & The News",
    id: 3116046,
    release_date: "1985-05-23",
    preview:
      "https://cdns-preview-2.dzcdn.net/stream/c-2b047e7c2823a55f5f498613c342409b-10.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/947835de11de3edaf23440d0078b0a92/250x250-000000-80-0-0.jpg",
    genre: "Rock",
  },
  {
    title: "Raspberry Beret",
    artist: "Prince",
    id: 775712,
    release_date: "1985-04-16",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-b92cf7a930a8a17d93adcf9d93c9eed9-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/52efb13d0fe33002a4079d321f7f9d69/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Price Tag",
    artist: "Jessie J",
    id: 14405185,
    release_date: "2011-11-14",
    preview:
      "https://cdns-preview-5.dzcdn.net/stream/c-51f9d6b549a89f2e89ab6d0a054b95a2-6.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2e830d5ee10ad8654e0d8ab70a18e54a/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Last Friday Night (T.G.I.F.)",
    artist: "Katy Perry",
    id: 17135109,
    release_date: "2010-03-23",
    preview:
      "https://cdns-preview-8.dzcdn.net/stream/c-8e33ff3569eaeb3556d5b1bbc2c697f1-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/99578b0bb8c838383c414a5b62b5e15d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "If I Were a Boy",
    artist: "Beyoncé",
    id: 2455091,
    release_date: "2008-10-17",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e199b72cf085dacb833e0c3931dc21ab-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/2a5cc391d209d80a3e3146772c41665c/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Shackles (Praise You)",
    artist: "Mary Mary",
    id: 626167,
    release_date: "2000-04-25",
    preview:
      "https://cdns-preview-f.dzcdn.net/stream/c-f3965f2250cb1ab24cc01bda19da6b3d-7.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/00ce09d9b4267630242eb0063a10f3ee/250x250-000000-80-0-0.jpg",
    genre: "Soul",
  },
  {
    title: "Sweet Child O' Mine",
    artist: "Guns N' Roses",
    id: 2171464,
    release_date: "1987-03-18",
    preview:
      "https://cdns-preview-b.dzcdn.net/stream/c-bd2e782bbd2e6ca929301cc66b5cbfd6-5.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/cover/88286a305ec9940bb7099fb5dac0211d/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
  {
    title: "Higher And Higher",
    artist: "Jackie Wilson",
    id: 558161542,
    release_date: "1967-01-01",
    preview:
      "https://cdns-preview-e.dzcdn.net/stream/c-e712e3f0894fc42e62baad9ff736c37a-2.mp3",
    cover:
      "https://e-cdns-images.dzcdn.net/images/artist/af0d35bde1677b99e056ec6dfa50b413/250x250-000000-80-0-0.jpg",
    genre: "Pop",
  },
];

export const tracksWithPreview = tracks.filter((t) =>
  trackTitlesWithPreview.includes(t.title)
);

export function getTrackTitle(track: Track): string {
  return track.title + " - " + track.artist;
}

export function sanitizeTrackTitle(trackTitle: string): string {
  return trackTitle
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
