import { Guess } from "../domain/guess";
import { GuessRow } from "./GuessRow";
import React from "react";
import { SettingsData } from "../hooks/useSettings";

interface GuessesProps {
  rowCount: number;
  guesses: Guess[];
  settingsData: SettingsData;
  trackInputRef?: React.RefObject<HTMLInputElement>;
}

export function Guesses({
  rowCount,
  guesses,
  settingsData,
  trackInputRef,
}: GuessesProps) {
  return (
    <div>
      <div className="grid grid-cols-12 gap-1 text-center">
        <div className="flex items-center justify-center h-4 col-span-5 animate-reveal">
          <p className="text-ellipsis overflow-hidden whitespace-nowrap">
            Song title
          </p>
        </div>
        <div className="flex items-center justify-center h-4 col-span-4 animate-reveal">
          <p className="text-ellipsis overflow-hidden whitespace-nowrap">
            Artist
          </p>
        </div>
        <div className="flex items-center justify-center h-4 col-span-2 animate-reveal">
          <p className="text-ellipsis overflow-hidden whitespace-nowrap">
            Genre
          </p>
        </div>
        <div className="flex items-center justify-center h-4 col-span-1 animate-reveal">
          <p className="text-ellipsis overflow-hidden whitespace-nowrap">Era</p>
        </div>
        {Array.from(Array(rowCount).keys()).map((index) => (
          <GuessRow
            key={index}
            guess={guesses[index]}
            settingsData={settingsData}
            trackInputRef={trackInputRef}
          />
        ))}
      </div>
    </div>
  );
}
