import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Game } from "./components/Game";
import React, { useEffect, useState } from "react";
import { Infos } from "./components/panels/Infos";
import { Settings } from "./components/panels/Settings";
import { useSettings } from "./hooks/useSettings";
import { Stats } from "./components/panels/Stats";

function App() {
  const [infoOpen, setInfoOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);

  const [settingsData, updateSettings] = useSettings();

  useEffect(() => {
    if (settingsData.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [settingsData.theme]);

  return (
    <>
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        theme={settingsData.theme}
        autoClose={2000}
        bodyClassName="font-bold text-center"
      />
      <Infos
        isOpen={infoOpen}
        close={() => setInfoOpen(false)}
        settingsData={settingsData}
      />
      <Settings
        isOpen={settingsOpen}
        close={() => setSettingsOpen(false)}
        settingsData={settingsData}
        updateSettings={updateSettings}
      />
      <Stats
        isOpen={statsOpen}
        close={() => setStatsOpen(false)}
        distanceUnit={settingsData.distanceUnit}
      />
      <div className="flex justify-center flex-auto dark:bg-slate-900 dark:text-slate-50">
        <div className="w-full max-w-lg flex flex-col">
          <header className="border-b-2 px-3 border-gray-200 flex">
            <button
              className="mr-3 text-xl"
              type="button"
              onClick={() => setInfoOpen(true)}
            >
              <img
                className={"h-8 w-10 dark:invert"}
                alt="Info icon"
                src={"./icons/info-svgrepo-com.svg"}
              />
            </button>
            {/* {supported() && !isInstalled() && (
              <InstallButton pwaInstall={pwaInstall} />
            )} */}
            <img
              className={"ml-2 text-xl h-8 w-10 dark:invert"}
              alt="Warbl note logo"
              src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
            />
            <h1 className="font-bold text-center my-1 flex-auto">
              <img
                className={"h-55 w-180 dark:invert"}
                alt="Warbl note logo"
                src={"./icons/warbl-logo.svg"}
              />
            </h1>
            <button
              className="ml-3 text-xl"
              type="button"
              onClick={() => setStatsOpen(true)}
            >
              <img
                className={"h-8 w-10 dark:invert"}
                alt="Game stats"
                src={"./icons/trophy-svgrepo-com.svg"}
              />
            </button>
            <button
              className="ml-3 text-xl"
              type="button"
              onClick={() => setSettingsOpen(true)}
            >
              <img
                className={"h-8 w-10 dark:invert"}
                alt="Settings"
                src={"./icons/gear-svgrepo-com.svg"}
              />
            </button>
          </header>
          <Game settingsData={settingsData} />
          <footer className="flex justify-center items-center text-sm mt-8 mb-1">
            <span>{}</span>
            <a href="https://buymeacoffee.com/nickholl">
              <span>
                <img
                  className={"h-7 dark:invert"}
                  alt="Buy me a coffee"
                  src={"./images/bmc-full-logo.svg"}
                />
              </span>
            </a>
            <img
              className={"h-8 w-10 dark:invert"}
              alt="Love"
              src={"./icons/heart-svgrepo-com.svg"}
            />
            <img
              className={"h-6 dark:invert"}
              alt="Warbl"
              src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
            />
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
