import { DateTime, Interval } from "luxon";
import { useMemo } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { generateShareCharacters } from "../domain/audio";
import { Guess } from "../domain/guess";
import React from "react";
import { SettingsData } from "../hooks/useSettings";
import { isMobile } from "react-device-detect";

const START_DATE = DateTime.fromISO("2022-04-14");

interface ShareProps {
  guesses: Guess[];
  dayString: string;
  settingsData: SettingsData;
  hideImageMode: boolean;
  rotationMode: boolean;
}

export function Share({
  guesses,
  dayString,
  settingsData,
  hideImageMode,
  rotationMode,
}: ShareProps) {
  const { t } = useTranslation();
  const { theme } = settingsData;

  const shareText = useMemo(() => {
    const score =
      guesses[guesses.length - 1] != null
        ? guesses[guesses.length - 1]?.score.title +
          guesses[guesses.length - 1]?.score.artist +
          guesses[guesses.length - 1]?.score.genre +
          guesses[guesses.length - 1]?.score.decade
        : 0;
    const win = score === 100;
    const guessCount = win ? guesses.length : "X";
    const dayCount = Math.floor(
      Interval.fromDateTimes(
        START_DATE.minus({ days: 1 }),
        DateTime.fromISO(dayString)
      ).length("day")
    );
    const difficultyModifierEmoji = hideImageMode
      ? " 🙈"
      : rotationMode
      ? " 🌀"
      : "";
    const bestPercent = `(${score}%)`;
    const title = `#Warbl #${dayCount} ${guessCount}/6 ${bestPercent}${difficultyModifierEmoji}`;

    const guessString = guesses
      .map((guess) => {
        const score =
          guess?.title != null
            ? guess.score.title +
              guess.score.artist +
              guess.score.genre +
              guess.score.decade
            : 0;
        const squares = generateShareCharacters(score, theme).join("");
        return `${squares}`;
      })
      .join("\n");
    return [title, guessString, "https://www.warbl.org"].join("\n");
  }, [dayString, guesses, hideImageMode, rotationMode, theme]);

  if (isMobile) {
    return (
      <button
        className="flex rounded-md items-center justify-center border-2 px-4 uppercase bg-green-600 hover:bg-green-500 active:bg-green-700 text-white w-full"
        onClick={() => {
          if (navigator.share) {
            navigator
              .share({
                text: shareText,
              })
              .then(() => console.log("Successful share"))
              .catch((error) => console.log("Error sharing", error));
          }
        }}
      >
        <span className="ml-1">{t("share")}</span>
        <img
          className={"h-4 w-5 dark:invert"}
          alt="Semiquaver logo"
          src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
        />
      </button>
    );
  }
  return (
    <CopyToClipboard
      text={shareText}
      onCopy={() => toast(t("copy"))}
      options={{
        format: "text/plain",
      }}
    >
      <button className="flex rounded-md items-center justify-center border-2 px-4 uppercase bg-green-600 hover:bg-green-500 active:bg-green-700 text-white w-full">
        <span className="ml-1">{t("share")}</span>
        <img
          className={"h-4 w-5 dark:invert"}
          alt="Semiquaver logo"
          src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
        />
      </button>
    </CopyToClipboard>
  );
}
