import { DateTime } from "luxon";
import { useMemo } from "react";
import seedrandom from "seedrandom";
import { tracksWithPreview, Track } from "../domain/tracks";

const forcedTracks: Record<string, string> = {
  "2022-04-03": "Don't Know Much",
  "2022-04-04": "Radio Ga Ga",
};

export function getDayString(shiftDayCount?: number) {
  return DateTime.now()
    .plus({ days: shiftDayCount ?? 0 })
    .toFormat("yyyy-MM-dd");
}

export function useTrack(dayString: string): [Track] {
  const track = useMemo(() => {
    const forcedTrackTitle = forcedTracks[dayString];
    const forcedTrack =
      forcedTrackTitle != null
        ? tracksWithPreview.find((track) => track.title === forcedTrackTitle)
        : undefined;

    return (
      forcedTrack ??
      tracksWithPreview[
        Math.floor(seedrandom.alea(dayString)() * tracksWithPreview.length)
      ]
    );
  }, [dayString]);

  return [track];
}
